import { Component, input } from '@angular/core';
import { STATIC_SECTION, GENERAL } from '../../../app.constants';
import { RouterLink } from '@angular/router';


@Component({
	selector: 'dflgr-static-footer',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './static-footer.component.html',
	styleUrls: ['./static-footer.component.scss']
})
export class StaticFooterComponent {
	readonly title = GENERAL.app.title;
	readonly SECTIONS = STATIC_SECTION; // make available STATIC_SECTION in template

	readonly showHR = input(true, { alias: 'show-hr' });

}
