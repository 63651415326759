import { Component, input, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import User from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { PATHS } from '../../../app.constants';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { RouterLink } from '@angular/router';
import { NgIf, NgStyle } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
	selector: 'dflgr-user-card-big',
	standalone: true,
	imports: [MatCardModule, NgStyle, RouterLink, CountryFlagComponent, MatButtonModule, MatIconModule, NgIf],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './user-card-big.component.html',
	styleUrls: ['./user-card-big.component.scss']
})
export class UserCardBigComponent {
	@HostBinding('attr.aria-label') readonly ariaLabel = 'User details';
	readonly user = input.required<User>();

	constructor(
		private readonly authProvider: AuthService
	) { }

	get isSelf() {
		return this.user()?.id === this.authProvider.localUser.id;
	}

	get editRoute() {
		return this.isSelf ? [`/${PATHS.user}`, this.user().username, 'edit'] : null;
	}

	get defaultAvatarBgStyle() {
		return this.user()?.defaultAvatarBgSrc;
	}
}
