import { Component, HostBinding, input, model } from '@angular/core';
import Country from '../../models/country.model';
// import * as COUNTRIESLIST from '@jagomf/countrieslist';
import { en as countriesEN } from '@jagomf/countrieslist';
import { AuthService } from '../../services/auth.service';
import { flag } from 'country-code-emoji';
import { ASSETS } from '../../../app.constants';
import { NgClass } from '@angular/common';

@Component({
	preserveWhitespaces: true,
	selector: 'dflgr-country-flag',
	standalone: true,
	imports: [NgClass],
	templateUrl: './country-flag.component.html',
	styleUrls: ['./country-flag.component.scss']
})
export class CountryFlagComponent {

	@HostBinding('class.item-country-flag') readonly classItemCountryFlag = true;
	readonly showFlag = input(true);
	readonly showName = input(true);
	readonly country = input.required<Country>();
	readonly lang = model('en');

	constructor(readonly authProvidetr: AuthService) {
		this.lang.set(this.authProvidetr.localLanguage);
	}

	get countryNameClassBindings() {
		return {
			'name-container': true,
			'hidden': !this.showName()
		};
	}

	get name(): string {
		// TODO MultiLang: return this.country?.id ? COUNTRIESLIST[this.lang][this.country.id.toUpperCase()] : '';
		return this.country()?.id ? countriesEN[this.country().id.toUpperCase()] : '';
	}

	get flagCode(): string {
		return this.country()?.id ? flag(this.country().id) : '';
	}

	get flagImgSrc(): string {
		const flagConst = ASSETS.flags;
		return this.country()?.id ? `${flagConst.path}${this.country().id}${flagConst.ext}` : '';
	}
}
