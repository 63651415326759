/* src/app/shared/handlers/front-page/front-page.component.scss */
:host {
  color: black;
  width: 100%;
}
header {
  position: relative;
}
.background-sky {
  background: #4dd0e1;
  background:
    linear-gradient(
      to bottom,
      #4dd0e1 0%,
      #4dd0e1 90%,
      rgba(0, 0, 0, 0) 100%);
}
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 480px;
  max-height: 560px;
  box-sizing: border-box;
  overflow: hidden;
}
#intro {
  display: flex;
  align-items: center;
  position: relative;
  height: 480px;
  margin: 0 auto;
  padding: 0;
  max-width: 600px;
}
@media (max-width: 599px) {
  #intro {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
  }
}
#intro .hero-logo {
  display: flex;
  min-width: 300px;
  height: 300px;
  background-size: 300px 300px;
  background-image: url(/assets/icons/vect-noback.svg);
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 599px) {
  #intro .hero-logo {
    justify-content: center;
  }
}
@media (max-width: 599px) {
  #intro .hero-logo {
    width: 240px;
    height: 240px;
    background-size: 240px 240px;
  }
}
#intro .homepage-container {
  width: 100%;
  max-width: 1040px;
  margin: -32px auto 0;
  padding-top: 0;
  padding-bottom: 0;
}
#intro .homepage-container .hero-headline {
  margin: 32px 0;
}
#intro .homepage-container .hero-headline-title {
  font-size: 4rem;
  font-weight: lighter;
}
#intro .homepage-container .hero-headline-tag {
  font-size: 2rem;
  font-weight: bold;
  font-style: oblique;
}
@media (max-width: 599px) {
  #intro .homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  #intro .homepage-container .hero-headline {
    text-align: center;
  }
  #intro .homepage-container .hero-headline-title {
    line-height: 5rem;
    font-size: 3.2rem;
  }
}
#intro .homepage-container .hero-stores {
  display: flex;
  flex-flow: row nowrap;
}
#intro .homepage-container .hero-stores .store {
  display: block;
  height: 3rem;
  width: 8.25rem;
  color: transparent;
}
#intro .homepage-container .hero-stores .store.apple-store {
  background-image: url(/assets/images/store-apple.svg);
}
#intro .homepage-container .hero-stores .store.google-store {
  background-image: url(/assets/images/store-google.svg);
}
#intro .homepage-container .hero-stores .store:last-child {
  margin-left: 1rem;
}
#intro .homepage-container .hero-cta {
  margin-top: 1rem;
}
article {
  padding: 32px;
}
@media (max-width: 599px) {
  article {
    padding: 24px;
  }
}
article .home-rows {
  overflow: hidden;
}
@media (max-width: 599px) {
  article .home-rows {
    margin: 0;
  }
}
article .home-rows .home-row {
  margin: 32px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 800px;
}
@media (max-width: 599px) {
  article .home-rows .home-row {
    display: block;
  }
}
article .home-rows .home-row .img-container,
article .home-rows .home-row .text-container {
  width: 50%;
}
@media (max-width: 599px) {
  article .home-rows .home-row .img-container,
  article .home-rows .home-row .text-container {
    width: 100%;
  }
}
article .home-rows .home-row .img-container img,
article .home-rows .home-row .text-container img {
  max-width: 90%;
}
article .home-rows .home-row .img-container .text-headline,
article .home-rows .home-row .text-container .text-headline {
  font-size: 1.3rem;
  font-weight: 500;
  color: #009faf;
  margin-top: 10px;
  text-transform: uppercase;
}
article .home-rows .home-row .img-container .text-body,
article .home-rows .home-row .text-container .text-body {
  font-size: 0.9rem;
  margin: 14px 0 0;
}
article .home-rows .home-row:nth-of-type(even) .img-container {
  order: 2;
}
article hr {
  border: none;
  height: 1px;
  background-color: #dbdbdb;
}
/*# sourceMappingURL=front-page.component.css.map */
