/* src/app/shared/components/user-country-list/user-country-list.component.scss */
:host {
  display: block;
}
@media (min-width: 600px) {
  :host {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }
}
.user-countries {
  margin: 5px;
}
/*# sourceMappingURL=user-country-list.component.css.map */
