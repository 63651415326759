import { Component, input, HostBinding } from '@angular/core';
import Country from '../../models/country.model';
import User from '../../models/user.model';
import { HotnessColorPipe } from '../../pipes/hotness-color.pipe';
import { MatExpansionModule } from '@angular/material/expansion';

import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import type { IFlagUser } from '../../services/flags.interfaces';

@Component({
	selector: 'dflgr-user-country',
	standalone: true,
	imports: [MatCardModule, RouterLink, CountryFlagComponent, MatExpansionModule, HotnessColorPipe],
	templateUrl: './user-country.component.html',
	styleUrls: ['./user-country.component.scss']
})
export class UserCountryComponent {

	@HostBinding('class.user-countries') readonly classUserCountries = true;
	@HostBinding('class.card') readonly classCard = true;
	@HostBinding('class.item') readonly classItem = true;
	readonly userFlag = input.required<IFlagUser>();
	readonly isGroup = input(false);

	getUserCountryLink(user: User, country: Country) {
		return user?.getUserCountryLink(country) ?? [];
	}
}
