/* src/app/shared/components/cropper-modal/cropper-modal.component.scss */
.cropper img {
  max-height: 100%;
  max-width: 100%;
}
.infotext {
  font-size: 0.8rem;
  margin: 0.3rem auto 0;
}
/*# sourceMappingURL=cropper-modal.component.css.map */
