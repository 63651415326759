@if (userFlags$() | async; as userFlags) {
	@if (selectedView === 8) {
		<div class="flags-container user-countries-container has-action-button collection"
			>
			<dflgr-user-country-list [list]="userFlags" [input]="input()"/>
		</div>
	} @else if (selectedView === 9) {
		<div class="flags-container world-map-container"
			>
			<dflgr-world-map [list]="userFlags" [input]="input()" (loadError)="errorLoadingMap()"/>
		</div>
	}
} @else {
	<div class="spinner-container"><mat-spinner/></div>
}

<dflgr-handler-action-button
	[icon]="actionButtonIcon" label="Toggle between List and Map views" [action]="actionButtonAction"/>
