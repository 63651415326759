import { Component, Inject, viewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CropperComponent } from '../cropper/cropper.component';
import type { ICropperModalData } from './cropper-modal.interfaces';
import { MatButtonModule } from '@angular/material/button';


const ENCODING_QUALITY = 0.8;
const PIC_SIDE = 250;

@Component({
	selector: 'dflgr-cropper-modal',
	standalone: true,
	imports: [MatDialogModule, CropperComponent, MatButtonModule],
	templateUrl: './cropper-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./cropper-modal.component.scss']
})
export class CropperModalComponent {

	private readonly cropperContainer = viewChild<CropperComponent>('cropperContainer');
	readonly image: string;
	readonly isEvent: boolean;

	readonly config: Cropper.Options = {
		// The aspect ratio of the crop box (1020/633, 16/9...)
		aspectRatio: 1,

		// A number between 0 and 1. Size (%) of the crop box in respect of canvas.
		autoCropArea: 0.50,

		// The minimum width of the canvas (image wrapper)
		minCanvasWidth: 120,

		// The minimum height of the canvas (image wrapper)
		minCanvasHeight: 120,

		// Restrict the crop box to not exceed the size of the canvas
		viewMode: 1,

		// Show the dashed lines above the crop box
		guides: false,

		// Show the white modal above the crop box (highlight the crop box).
		highlight: false,

		// Move the canvas.
		dragMode: 'none',

		// Enable to move the crop box
		cropBoxMovable: true,

		// Enable to resize the crop box
		cropBoxResizable: true,

		// Rebuild the cropper when resize the window
		responsive: true,

		// Read EXIF Orientation value and override (to avoid iOS Rotation issue).
		checkOrientation: true,

		// Enable to rotate the image
		rotatable: true,

		// Disable toggle drag mode on double click/tap
		toggleDragModeOnDblclick: false
	};

	constructor(
		private readonly dialogRef: MatDialogRef<CropperModalComponent, string>,
		@Inject(MAT_DIALOG_DATA) readonly data: ICropperModalData,
	) {
		this.image = data.image;
		this.isEvent = !!data.isEvent;
		if (this.isEvent) {
			this.config.aspectRatio = 1000 / 600;
		}
	}

	get isAvatar() {
		return !this.isEvent;
	}

	onAcceptCrop() {
		const options: Cropper.GetCroppedCanvasOptions = {
			fillColor: 'white'
		};
		if (this.isAvatar) {
			options.width = PIC_SIDE;
			options.height = PIC_SIDE;
		}

		const canvas = this.cropperContainer().cropper.getCroppedCanvas(options);

		const base64 = canvas.toDataURL('image/jpeg', ENCODING_QUALITY);

		this.dialogRef.close(base64);
	}

}
