/* src/app/shared/components/friend-actions/friend-actions.component.scss */
:host.vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
:host.vertical button {
  width: 100%;
  text-align: left;
}
/*# sourceMappingURL=friend-actions.component.css.map */
