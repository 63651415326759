import { Component, HostBinding, input, computed, effect } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import Group from '../../models/group.model';
import User from '../../models/user.model';
import { GENERAL } from '../../../app.constants';
import { WorldCountryListComponent } from '../../components/world-country-list/world-country-list.component';
import { HeaderHandlerComponent } from '../../components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-flags-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, WorldCountryListComponent],
	templateUrl: './flags-handler.component.html',
	styleUrls: ['./flags-handler.component.scss']
})
export class FlagsHandlerComponent {
	@HostBinding('class.handler') readonly handlerClass = true;
	titleHolder: string;
	readonly input = computed(() => this.user() || this.group());
	/**
	 * Setting `user` excludes from setting `group` as well. Use one or another.
	 */
	readonly user = input<User>(); // from route resolver
	/**
	 * Setting `group` excludes from setting `user` as well. Use one or another.
	 */
	readonly group = input<Group>(); // from route resolver

	constructor(
		private readonly authProvider: AuthService,
	) {
		effect(() => {
			if (this.input() instanceof Group) {
				this.titleHolder = `${GENERAL.handlers.groups.flags.name}: ${this.input().name}`;
			} else {
				const isSelf = this.input().id === this.authProvider.localUser.id;
				this.titleHolder = isSelf ? 'My Flags' : `${(this.input() as User).username || this.input().name}: Flags`;
			}
		});
	}

}
