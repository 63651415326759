/* src/app/shared/components/flag-event-list-filtered/flag-event-list-filtered.component.scss */
:host {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.user-card {
  margin: 10px;
}
@media (min-width: 600px) {
  .user-card {
    margin: 1em auto;
  }
}
@media (min-width: 600px) {
  .card,
  .item {
    max-width: 400px;
  }
}
.spinner-container {
  margin-top: 60px;
}
.flaglist-container .user-card-wrapper {
  width: 100%;
}
.flaglist-container .user-card {
  width: auto;
}
@media (min-width: 600px) {
  .flaglist-container .user-card {
    width: 100%;
  }
}
.loading-more {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  font-style: oblique;
  background:
    linear-gradient(
      to bottom,
      rgb(255, 255, 255) 0%,
      rgb(219, 219, 219) 50%,
      rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=0);
}
/*# sourceMappingURL=flag-event-list-filtered.component.css.map */
