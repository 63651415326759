/* src/app/shared/components/user-card-big/user-card-big.component.scss */
:host {
  display: block;
}
@media (min-width: 600px) {
  :host {
    max-width: 27em;
    min-width: 20em;
  }
}
.user-card {
  min-height: 8em;
}
.user-card .avatar-container {
  margin: -8px 2rem -3.6rem;
}
.user-card .avatar-container img {
  max-width: 7rem;
  max-height: 7rem;
  min-width: 7rem;
  min-height: 7rem;
  display: block;
  background-size: cover;
  margin: 0;
}
.user-card .card-big-title {
  padding: 16px;
}
.user-card .card-big-title-main {
  font-size: 24px;
  font-weight: 500;
}
.user-card .card-big-title-sub {
  font-size: 14px;
}
.user-card .card-big-content {
  padding-right: 120px;
  font-size: 14px;
}
.user-card .card-big-content a {
  text-decoration: none;
  color: inherit;
}
/*# sourceMappingURL=user-card-big.component.css.map */
