/* src/app/shared/handlers/ranking-handler/ranking-handler.component.scss */
.handler-content {
  padding: 5px;
}
.handler-content.ranking ::ng-deep mat-expansion-panel-header mat-panel-title {
  flex: 1;
  overflow: hidden;
}
.handler-content.ranking ::ng-deep mat-expansion-panel-header mat-panel-description {
  flex: 0 1 auto;
  color: rgba(0, 0, 0, 0.5411764706);
}
.handler-content.ranking ::ng-deep mat-expansion-panel-header.mat-expanded mat-panel-description {
  display: none;
}
.title-rank {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.title-rank.rank-1 {
  font-size: 1.5rem;
}
.title-rank.rank-2 {
  font-size: 1.25rem;
}
.title-rank.rank-3 {
  font-size: 1.1rem;
}
.title-username {
  margin-left: 5px;
}
a.single-country {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  margin: 0.2rem;
  padding: 0.2rem 0.3rem;
  border-radius: 0.4rem;
}
.flags-count {
  justify-content: flex-end;
  align-items: center;
}
.flags-count-inner {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5411764706);
  margin-bottom: 0.4rem;
}
/*# sourceMappingURL=ranking-handler.component.css.map */
