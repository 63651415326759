<h2 mat-dialog-title>@if (newFlag) { New flag! Yay! } @else { Edit flag }</h2>
<form [formGroup]="addFlagForm" (ngSubmit)="requestAddFlag()">
	<mat-dialog-content class="form-content">
		<div class="form-block center-block full-width">
			<div class="form-field has-mat-form-field bottom-space">
				<mat-button-toggle-group formControlName="isDouble" [hideSingleSelectionIndicator]="true">
					<mat-button-toggle [value]="false" matTooltip="Single nationality">
						<mat-icon>flag</mat-icon>
					</mat-button-toggle>
					<mat-button-toggle [value]="true" matTooltip="Double nationality">
						<mat-icon>flag</mat-icon><mat-icon>flag</mat-icon>
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</div>
		<div class="form-block country-joiner">
			<div class="form-field has-mat-form-field">
				<dflgr-select-country label="Country{{isDouble ? ' 1' : ''}}" formControlName="selectedCountry1" [excluded]="isDouble && addFlagForm.controls.selectedCountry2.value"/>
			</div>
			@if (isDouble) {
				<div class="form-field has-mat-form-field">
					<dflgr-select-country label="Country 2" formControlName="selectedCountry2" [excluded]="addFlagForm.controls.selectedCountry1.value" [required]="isDouble"/>
				</div>
			}
		</div>
		<div class="form-block">
			<div class="form-field has-mat-form-field fake-label-container full-datetime">
				<span class="fake-label">When?</span>
				<div class="now-joiner">
					<mat-checkbox formControlName="isNow" labelPosition="before">Now</mat-checkbox>
					<mat-form-field class="input-datetime">
						<input matInput type="datetime-local" formControlName="eventDate" />
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="form-block full-width">
			<div class="form-field has-mat-form-field">
				<div class="form-event-pic center-block">
					@if (eventPic.value) {
						<img class="event-pic-img" [src]="eventPic.value | safeImage" alt="Event picture"/>
					}
					<br/>
					<input type="hidden" formControlName="eventPic" #eventPic/>
					<button type="button" mat-raised-button (click)="requestChangePicture();">{{eventPic.value ? 'Change' : 'Add'}} picture</button>
					@if (eventPic.value) {
						<button type="button" mat-raised-button (click)="requestDeletePicture()">Delete picture</button>
					}
				</div>
			</div>
		</div>
		<div class="form-block full-width">
			<div class="form-field has-mat-form-field">
				<mat-form-field class="full-width">
					<mat-label>Remarks</mat-label>
					<textarea matInput type="text" [cdkTextareaAutosize] formControlName="eventComments" placeholder="Remarks"></textarea>
					<mat-hint>Any remarks regarding this event&hellip;?</mat-hint>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions class="form-actions">
		<button type="submit" [disabled]="addFlagForm.invalid" mat-raised-button color="accent">
			<span class="flag-action">@if (newFlag) {
				<mat-icon>add</mat-icon><mat-icon>flag</mat-icon>Add flag!
			} @else {
				<mat-icon>edit</mat-icon> Edit flag
			}</span>
		</button>
		<button mat-button mat-dialog-close>Nope</button>
	</mat-dialog-actions>
</form>
