<mat-form-field>
	<input matInput #input (keyup)="textTyped(input)" [placeholder]="placeholder()" [matAutocomplete]="autoCountries"
		[(ngModel)]="selected" (blur)="onBlur()">
	<mat-label *ngIf="label()">{{label()}}</mat-label>
	<mat-error *ngIf="!isValid">Select a country</mat-error>
	<mat-autocomplete #autoCountries="matAutocomplete" (optionSelected)="countrySelected($event)" [displayWith]="displayFn">
		@for (country of filtered; track country.id) {
			@if (!isExcluded(country)) {
				<mat-option [value]="country">
					<dflgr-country-flag [country]="country"/>
				</mat-option>
			}
		}
	</mat-autocomplete>
</mat-form-field>
