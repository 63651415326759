/* src/app/shared/components/world-map/world-map.component.scss */
:host {
  display: block;
  height: 100%;
  background-color: white;
}
.caption-times-totals {
  padding: 0 10px;
}
@media (min-width: 600px) {
  .caption-times-totals .card-times {
    width: fit-content;
  }
}
.caption-times-totals .card-times .card-header a {
  color: unset;
}
.caption-times-totals .card-times .group-flag-total {
  font-weight: bold;
}
/*# sourceMappingURL=world-map.component.css.map */
