import { Component, input, ChangeDetectionStrategy, ContentChild, ElementRef } from '@angular/core';
import type { IMenuOptions, IMenuFilter } from '../../services/header.interfaces';
import { ViewableActionsPipe } from '../../pipes/viewable-actions.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NgTemplateOutlet } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
	selector: 'dflgr-extended-menu',
	standalone: true,
	imports: [MatMenuModule, MatIconModule, MatButtonModule, NgTemplateOutlet, ViewableActionsPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './extended-menu.component.html',
	styleUrls: ['./extended-menu.component.scss']
})
export class ExtendedMenuComponent {
	@ContentChild('hasChildren') hasChildren: ElementRef;
	readonly options = input<IMenuOptions[]>();
	readonly filters = input<IMenuFilter[]>();
	readonly sorters = input<IMenuFilter[]>();
	readonly letContent = input(false);

	get hasOverflow(): boolean {
		const pipe = new ViewableActionsPipe();
		return (!this.options() && (!!this.hasChildren || this.letContent()))
			|| (this.options() && !!pipe.transform(this.options(), true).length);
	}
}
