import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import type Country from '../../../shared/models/country.model';
import { SubscribableBaseComponent } from '../../../shared/components/subscribable-base/subscribable-base.component';
import { CropperModalComponent } from '../../../shared/components/cropper-modal/cropper-modal.component';
import { FlagsService } from '../../../shared/services/flags.service';
import { AlertService } from '../../../shared/services/alert.service';
import Utils from '../../../shared/app.utils';
import type { IAddFlagData } from './add-flag-modal.interfaces';
import type { ICropperModalData } from '../../../shared/components/cropper-modal/cropper-modal.interfaces';
import { SafeImagePipe } from '../../pipes/safe-image.pipe';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectCountryComponent } from '../../../shared/components/select-country/select-country.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { type AbstractControl, FormBuilder, FormControl, ReactiveFormsModule, type ValidationErrors, Validators } from '@angular/forms';

export const AddFlagModalSize = (): MatDialogConfig => ({
	maxWidth: '430px',
	width: '80vw',
	minWidth: '318px'
});

const dateZoneToUtc = (dt: Date) => {
	return new Date(dt.getTime() + (dt.getTimezoneOffset() + 60000));
};
const setValueToDateTimeInput = (control: FormControl<Date>, date: Date) => {
	(control as FormControl<unknown>).setValue(date.toISOString().slice(0, 16));
};

const clearBase64Encoding = (base64str: string) => base64str.replace('data:image/jpeg;base64,', '');

@Component({
	selector: 'dflgr-add-flag-modal',
	standalone: true,
	imports: [MatDialogModule, ReactiveFormsModule, MatButtonToggleModule, MatTooltipModule, MatIconModule, SelectCountryComponent, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatButtonModule, TextFieldModule, SafeImagePipe],
	templateUrl: './add-flag-modal.component.html',
	styleUrls: ['./add-flag-modal.component.scss']
})
export class AddFlagModalComponent extends SubscribableBaseComponent {

	newFlag = true;

	readonly addFlagForm = this.fb.group({
		isDouble: new FormControl<boolean>(false),
		selectedCountry1: new FormControl<Country>(null, { validators: Validators.required, nonNullable: true }),
		selectedCountry2: new FormControl<Country | null>(null, { validators: AddFlagModalComponent.conditionalCountry2RequirementValidation }),
		isNow: new FormControl<boolean>(true, { validators: Validators.required, nonNullable: true }),
		eventDate: new FormControl<Date | null>({ value: new Date(), disabled: true }),
		eventPic: new FormControl<string | null>(null),
		eventComments: new FormControl<string | null>(null)
	});

	constructor(
		private readonly fb: FormBuilder,
		private readonly dialogRef: MatDialogRef<AddFlagModalComponent, void>,
		@Inject(MAT_DIALOG_DATA) private readonly data: IAddFlagData,
		private readonly modalHandler: MatDialog,
		private readonly alertService: AlertService,
		private readonly flagsProvider: FlagsService
	) {
		super();
		if (this.data?.country) {
			this.addFlagForm.controls.selectedCountry1.setValue(this.data.country);

		} else if (this.data?.event) {
			this.newFlag = false;
			const event = this.data.event;
			this.addFlagForm.setValue({
				selectedCountry1: event.country1,
				selectedCountry2: event.country2 ?? null,
				isDouble: !!event.country2,
				isNow: false,
				eventDate: event.eventDate,
				eventComments: event.text,
				eventPic: event.imgSrc,
			});
			this.addFlagForm.controls.eventDate.enable();
		} else {
			setValueToDateTimeInput(this.addFlagForm.controls.eventDate, new Date());
		}

		this.formLiveChecks();
	}

	private static conditionalCountry2RequirementValidation(formControl: AbstractControl): ValidationErrors | null {
		const ret = (formControl.parent?.get('isDouble') as FormControl<boolean>)?.value
			? Validators.required(formControl)
			: null;
		return ret;
	}

	get isDouble(): boolean {
		return this.addFlagForm.controls.isDouble.value;
	}

	private formLiveChecks(): void {
		this.addFlagForm.controls.isDouble.valueChanges.subscribe(isDouble => {
			if (isDouble) {
				this.addFlagForm.controls.selectedCountry2.setValidators(Validators.required);
			} else {
				this.addFlagForm.controls.selectedCountry2.setValue(null);
				this.addFlagForm.controls.selectedCountry2.setValidators(null);
			}
			this.addFlagForm.controls.selectedCountry2.updateValueAndValidity();
		});
		this.addFlagForm.controls.isNow.valueChanges.subscribe(isNow => {
			if (isNow) {
				this.addFlagForm?.controls.eventDate.disable();
				setValueToDateTimeInput(this.addFlagForm?.controls.eventDate, new Date());
				this.addFlagForm?.controls.eventDate.setValidators(null);
			} else {
				this.addFlagForm?.controls.eventDate.enable();
				this.addFlagForm?.controls.eventDate.setValidators(Validators.required);
			}
			this.addFlagForm.controls.eventDate.updateValueAndValidity();
		});
	}

	async requestChangePicture() {
		try {
			const fourMb = 4145728;
			const base64str = await Utils.onClickSendMedia(fourMb, false, 'image/*');
			this.requestCropPicture(Array.isArray(base64str) ? base64str[0] : base64str);
		} catch (err) {
			this.alertService.publish({ msg: err.toString(), duration: 5000, action: 'Damn' });
		}
	}

	private requestCropPicture(base64str: string) {
		this.modalHandler.open<CropperModalComponent, ICropperModalData, string>(CropperModalComponent, {
			data: { image: base64str, isEvent: true }
		}).afterClosed()
			.subscribe(base64jpg => {
				if (base64jpg) {
					this.addFlagForm.controls.eventPic.setValue(base64jpg);
				}
			});
	}

	requestDeletePicture() {
		this.addFlagForm.controls.eventPic.reset();
	}

	requestAddFlag() {
		const { selectedCountry1: nation1, selectedCountry2: nation2, isDouble: double,
			isNow: now, eventDate, eventComments, eventPic } = this.addFlagForm.value;
		const processedEventDate = now ? new Date() : dateZoneToUtc(new Date(eventDate));

		// Create mode
		if (this.newFlag) {
			this.flagsProvider.addFlagEvent(
				nation1,
				double ? nation2 : null,
				processedEventDate.toISOString(),
				eventComments === '' ? null : eventComments || null,
				eventPic ? clearBase64Encoding(eventPic) : null // remove base64 header
			)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(created) => {
						if (created) {
							this.dialogRef.close();
						} else {
							this.alertService.publish({
								msg: 'You have already created a Flag on the same date and time!',
								duration: 5000,
								action: 'Ah, right'
							});
						}
					},
					() => {/* show error */}
				);

		// Edit mode
		} else {
			const initial = this.data.event;

			const changedCountry1 = initial.country1.id !== nation1.id;
			const changedCountry2 = initial.country2?.id !== nation2?.id;
			const changedWhen = initial.eventDate.toLocaleString() !== processedEventDate.toLocaleString();
			const changedText = initial.text !== eventComments;
			const changedPic = initial.img !== eventPic;

			if (changedCountry1 || changedCountry2 || changedWhen || changedText || changedPic) {
				this.flagsProvider.editFlagEvent(
					initial.id,
					nation1,
					double ? nation2 : null,
					changedWhen ? processedEventDate.toISOString() : null,
					changedText ? !eventComments || eventComments === '' ? '' : eventComments : null,
					changedPic ? !eventPic ? '' : clearBase64Encoding(eventPic) : null
				)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(
						edited => {
							if (edited) {
								this.dialogRef.close();
							} else {
								this.alertService.publish({
									msg: 'Some error prevented from editing. Connection maybe?',
									duration: 5000,
									action: 'Oh wow'
								});
							}
						},
						() => {/* show error */}
					);

			} else { // nothing actually changed
				this.dialogRef.close();
			}
		}
	}
}
