/* src/app/shared/components/nav-icon/nav-icon.component.scss */
:host {
  cursor: pointer;
}
.item-wrapper {
  color: black;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.text {
  font-size: 10px;
  line-height: 10px;
}
/*# sourceMappingURL=nav-icon.component.css.map */
