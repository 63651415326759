<dflgr-header-handler [backButton]="true" [title]="handlerTitle()"/>

<div class="handler-content ranking">

@if (rankingSourceList$() | async; as rankedUserList) {
	@if (rankedUserList.length) {
		<mat-accordion>
		@for (item of rankedUserList; track item.user.id) {
		<mat-expansion-panel>
			<ng-template #flagcount>{{item.countries.length}} flag(s)</ng-template>
			<mat-expansion-panel-header>
				<mat-panel-title>
					<span class="title-rank rank-{{item.rank}}">
						#{{item.rank}}{{getMedal(item.rank)}}
						<span class="title-username">{{item.user.username}}</span>
					</span>
				</mat-panel-title>
				<mat-panel-description class="flags-count">
					<ng-template [ngTemplateOutlet]="flagcount"/>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<ng-template matExpansionPanelContent>
				<div class="flags-count-inner"><ng-template [ngTemplateOutlet]="flagcount"/>:</div>
				@for (country of item.countries; let isLast = $last; track country.id) {
					<a class="single-country mat-elevation-z2" [routerLink]="country.link">
						<dflgr-country-flag [country]="country"/>
					</a>
				}
			</ng-template>
		</mat-expansion-panel>
		}
		</mat-accordion>
	} @else {
		<div class="empty-container">No friends to rank!</div>
	}
} @else {
	<div class="handler-content">
		<div class="spinner-container"><mat-spinner/></div>
	</div>
}

</div>
