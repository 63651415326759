import { Component, input, computed, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FlagsService } from '../../../shared/services/flags.service';
import User from '../../../shared/models/user.model';
import Group from '../../../shared/models/group.model';
import { AlertService } from '../../services/alert.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HandlerActionButtonComponent } from '../handler-action-button/handler-action-button.component';
import { WorldMapComponent } from '../world-map/world-map.component';
import { UserCountryListComponent } from '../user-country-list/user-country-list.component';
import { AsyncPipe } from '@angular/common';

export enum COUNTRIES_VIEW { flags = 8, map = 9 }

@Component({
	selector: 'dflgr-world-country-list',
	standalone: true,
	imports: [UserCountryListComponent, WorldMapComponent, HandlerActionButtonComponent, MatProgressSpinnerModule, AsyncPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './world-country-list.component.html',
	styleUrls: ['./world-country-list.component.scss']
})
export class WorldCountryListComponent /* implements OnChanges */ {

	readonly input = input.required<User | Group>();
	selectedView ?= COUNTRIES_VIEW.flags;
	canShowMap = true;

	readonly userFlags$ = computed(() => this.flagsProvider.getFlagsForUserOrGroup(this.input()));

	constructor(
		private readonly cdRef: ChangeDetectorRef,
		private readonly flagsProvider: FlagsService,
		private readonly alertService: AlertService,
	) { }

	get actionButtonIcon() {
		return this.selectedView === COUNTRIES_VIEW.flags ? 'map' : 'list';
	}
	get actionButtonAction() {
		return this.canShowMap && this.toggleCountriesView.bind(this);
	}

	private toggleCountriesView() {
		this.selectedView = this.selectedView === COUNTRIES_VIEW.flags
			? COUNTRIES_VIEW.map
			: COUNTRIES_VIEW.flags;
		this.cdRef.detectChanges();
	}

	errorLoadingMap() {
		this.selectedView = COUNTRIES_VIEW.flags;
		this.canShowMap = false;
		this.cdRef.detectChanges();
		this.alertService.publish({
			msg: 'Error loading map! Check your connection and try again later',
			action: 'Damn'
		});
	}
}
