/* src/app/shared/components/user-card-list/user-card-list.component.scss */
:host {
  display: block;
  padding: 5px;
}
@media (min-width: 600px) {
  :host {
    display: flex;
    flex-flow: row wrap;
  }
}
.item {
  margin: 5px auto;
}
@media (min-width: 600px) {
  .item {
    max-width: 400px;
    align-self: center;
    margin: 5px;
  }
}
/*# sourceMappingURL=user-card-list.component.css.map */
