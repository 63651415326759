import { Component, model, HostBinding, input, OnInit, output, OnChanges, SimpleChange } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { FLAGEVENTS, EVENTSWITHFRIENDS } from '../../../app.constants';
import { inOutItems } from '../../app.animations';
import Country from '../../models/country.model';
import FlagEvent from '../../models/flag-event.model';
import User from '../../models/user.model';
import { SubscribableBaseComponent } from '../subscribable-base/subscribable-base.component';
import { AuthService } from '../../services/auth.service';
import { FlagsService } from '../../services/flags.service';
import { AlertService } from '../../../shared/services/alert.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlagEventComponent } from '../flag-event/flag-event.component';
import { InfiniteScrollerDirective } from '../../directives/infinite-scroller.directive';
import { UserCardBigComponent } from '../user-card-big/user-card-big.component';
import { NgTemplateOutlet } from '@angular/common';

interface IPaginator {
	start: number;
	remain: boolean;
}

const PAGINATOR_INIT = { remain: true, start: 0 };

@Component({
	animations: [ inOutItems ],
	selector: 'dflgr-flag-event-list-filtered',
	standalone: true,
	imports: [UserCardBigComponent, InfiniteScrollerDirective, NgTemplateOutlet, FlagEventComponent, MatProgressSpinnerModule],
	templateUrl: './flag-event-list-filtered.component.html',
	styleUrls: ['./flag-event-list-filtered.component.scss']
})
export class FlagEventListFilteredComponent extends SubscribableBaseComponent implements OnInit, OnChanges {

	readonly groupId = input<string>();
	readonly country = input<Country>();
	readonly singleUser = input<User>();
	paginator: IPaginator = {...PAGINATOR_INIT};
	readonly flagEventList = model<FlagEvent[]>();
	readonly listUpdated = output<FlagEvent[] | void>();
	@HostBinding('class.collection') readonly classCollection = true;
	readonly showOnlyBy = model(EVENTSWITHFRIENDS.YES);
	firstLoad = true;
	loadingMore = false;
	infiniteBinding = this.retrieveFlagEvents.bind(this);

	readonly EVENTSWITHFRIENDS = EVENTSWITHFRIENDS;

	private get withFriends() {
		return this.singleUser()
			? EVENTSWITHFRIENDS.NO
			: this.showOnlyBy();
	}

	private get countryOrAll() {
		return this.country() || null;
	}

	constructor(
		private readonly flagsProvider: FlagsService,
		private readonly alertService: AlertService,
		private readonly authProvider: AuthService
	) {
		super();
		this.showOnlyBy.set(this.singleUser ? EVENTSWITHFRIENDS.NO : EVENTSWITHFRIENDS.YES);
		this.flagEventList.set(this.flagEventList() || []);
	}

	private get localUser(): User {
		return this.authProvider.localUser;
	}

	private get user(): User {
		return this.singleUser() || this.localUser;
	}

	private changeFilter() {
		this.resetListAndPagination();
		this.doCall(true);
	}

	private resetListAndPagination() {
		this.firstLoad = true;
		this.paginator = {...PAGINATOR_INIT};
		this.flagEventList.set([]);
	}

	private retrieveFlagEvents() {
		this.loadingMore = !this.firstLoad;
		return this.flagsProvider.getFlagEvents(
			this.user,
			this.paginator.start,
			this.countryOrAll,
			this.withFriends,
			this.groupId()
		).pipe(tap((newEventsList: FlagEvent[]) => {
			this.updatePage(newEventsList.length);
			this.flagEventList.set(this.flagEventList().concat(newEventsList));
			this.firstLoad = false;
			this.loadingMore = false;
			this.listUpdated.emit();
		}));
	}

	/**
	 * Updates results pagination.
	 */
	private updatePage(newFlagEventsLength: number) {
		// If list is empty or has less elements than requested, then no more items are expected
		const listIsFinished = newFlagEventsLength === 0 || newFlagEventsLength < FLAGEVENTS.howManyEachTime;

		if (!listIsFinished) {
			this.paginator.start += newFlagEventsLength;
		}

		this.paginator.remain = !listIsFinished;
	}

	private effectivelyAddFlagEvent(flagEvent: FlagEvent) {
		const alreadyExistsId = this.flagEventList().findIndex(item => item.id === flagEvent.id);
		if (alreadyExistsId > -1) { // edited flag
			this.flagEventList()[alreadyExistsId] = flagEvent;
		} else { // new flag
			// this.flagEventList.unshift(flagEvent);
			this.flagEventList.set([flagEvent].concat(this.flagEventList()));
		}
		window.navigator.vibrate([100, 50, 100, 50, 400]);
		this.listUpdated.emit(this.flagEventList());
	}

	private onFlagEventCreated(flagEvent: FlagEvent) {
		// If showing from specific country
		if (this.country()) {
			// only add if belongs to this country
			if (flagEvent.country1.id === this.country().id
				|| flagEvent.country2?.id === this.country().id) {
				this.effectivelyAddFlagEvent(flagEvent);
			}
		} else if (this.singleUser()) { // If showing some user's events
			if (this.authProvider.localUser.id === flagEvent.user.id) { // add if showing self's
				this.effectivelyAddFlagEvent(flagEvent);
			}
		} else { // else add in any case
			this.effectivelyAddFlagEvent(flagEvent);
		}
	}

	removeFlagEvent(flagEvent: FlagEvent) {
		// const pos = this.flagEventList.indexOf(flagEvent);
		// this.flagEventList.splice(pos, 1);
		this.flagEventList.set(this.flagEventList().reduce((res: FlagEvent[], item) => {
			if (item.id !== flagEvent.id) {
				res.push(item);
			}
			return res;
		}, []));
		window.navigator.vibrate([300]);
		this.alertService.publish({
			msg: 'Event deleted!',
			action: 'I know',
			duration: 2000
		});
		this.listUpdated.emit(this.flagEventList());
	}

	ngOnInit() {
		// Subscribe to new and edited Flag events
		this.flagsProvider.flagEventCreatedOrEdited()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((flagEvent) => this.onFlagEventCreated(flagEvent));

		// If not a single event
		if (this.flagEventList().length === 0) {
			this.doCall();
		} else {
			this.firstLoad = false;
		}
	}

	ngOnChanges({ country, showOnlyBy, singleUser }: { country: SimpleChange, showOnlyBy: SimpleChange, singleUser: SimpleChange }) {
		if ((country && !country.isFirstChange()) || (showOnlyBy && !showOnlyBy.isFirstChange()) || (singleUser && !singleUser.isFirstChange())) {
			this.changeFilter();
		}
	}

	private doCall(cancelPreviousSubscription = false) {
		if (cancelPreviousSubscription) {
			this.ngOnDestroy();
		}
		// Force first call
		this.retrieveFlagEvents()
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(() => {});
	}
}
