/* src/app/shared/components/flag-event/flag-event.component.scss */
:host {
  flex: 1 1 auto;
  margin: 5px;
  width: 267px;
  align-self: center;
}
@media (max-width: 599px) {
  :host {
    width: 100%;
    margin: 5px 0;
  }
}
.event-pic {
  width: 90%;
  max-width: 400px;
  margin: 10px 0;
}
.card-comment {
  margin-top: 0.7rem;
  font-style: oblique;
  position: relative;
  background: rgba(0, 0, 0, 0.0352941176);
  border-radius: 0.7rem;
  padding: 0.3rem;
  text-align: center;
}
.card-comment:after {
  content: "";
  position: absolute;
  top: 0;
  left: 1.3rem;
  width: 0;
  height: 0;
  border: 0.8rem solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.0352941176);
  border-top: 0;
  margin-left: -0.8rem;
  margin-top: -0.8rem;
}
@media (min-width: 600px) {
  .card-comment:after {
    top: 1px;
  }
}
.card-comment .comment-content {
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
@media (max-width: 599px) {
  .card-comment .comment-content {
    display: inline-block;
    cursor: auto;
  }
}
.card-comment .comment-content-clamped {
  line-clamp: 1;
  -webkit-line-clamp: 1;
}
.delete-flag-event-button,
.delete-flag-event-button mat-icon {
  color: red;
}
.card-header {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0.8rem;
  padding-left: 0.8rem;
  height: 1.5rem;
}
.mat-mdc-card-content {
  margin-bottom: 0;
}
.card-content {
  display: flex;
  flex-flow: row nowrap;
}
.card-content a.country-link {
  text-decoration: none;
}
.card-content .card-avatar {
  margin-right: 0.5rem;
}
@media (max-width: 599px) {
  .card-content .card-avatar {
    align-self: center;
  }
}
.card-content .card-avatar .avatar {
  min-width: 2.5rem;
  min-height: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
  display: block;
  background-size: cover;
  margin-bottom: 0;
}
.card-content .card-main {
  flex: 1;
  font-size: 1rem;
}
@media (max-width: 599px) {
  .card-content .card-main {
    align-self: center;
  }
}
.card-content .card-flags {
  display: flex;
  flex-flow: row wrap;
}
.card-content .card-flags ::ng-deep .item-country-flag .flag-container img {
  width: 2.5rem;
}
.card-content .card-flags ::ng-deep .item-country-flag .flag-container .flag-utf {
  font-size: 2.5rem;
  margin-top: -0.3rem;
}
.card-content .card-flags ::ng-deep .item-country-flag:last-of-type {
  margin-left: 0.5rem;
}
.card-footer {
  padding: 0.3rem 0.6rem 0.3rem;
  display: flex;
  align-items: center;
}
.card-footer .social-status {
  transition: 0.3s ease-in;
}
.card-footer .event-unliked {
  transform: scale(0.9);
  opacity: 0.5;
}
.card-footer .event-liked {
  transform: rotate(15deg);
}
.card-footer .likes-count {
  font-size: 0.8rem;
  flex: 1;
}
.card-footer .card-link {
  font-size: 0.8rem;
  display: flex;
  flex-flow: row nowrap;
}
.card-footer .card-link-icon {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
}
.card-footer .self-actions {
  margin-top: -0.75rem;
  margin-right: -0.75rem;
  cursor: pointer;
  align-self: flex-end;
}
/*# sourceMappingURL=flag-event.component.css.map */
