import { Component, input, computed, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, output } from '@angular/core';
import { ChartSelectEvent, CountriesData, CountryExtraData, CountriesMapModule } from 'countries-map';
import { KEYS } from '../../../app.constants';
import Country from '../../models/country.model';
import Group from '../../models/group.model';
import User from '../../models/user.model';
import type { IFlagUser } from '../../services/flags.interfaces';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';


@Component({
	selector: 'dflgr-world-map',
	standalone: true,
	imports: [CountriesMapModule, MatCardModule, RouterLink, CountryFlagComponent, MatProgressSpinnerModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './world-map.component.html',
	styleUrls: ['./world-map.component.scss']
})
export class WorldMapComponent implements OnInit {

	readonly input = input.required<Group | User>();
	readonly list = input.required<IFlagUser[]>();
	selectedCountry: Country;
	loading = true;
	hasError = false;
	readonly loadError = output<void>();
	// Data for CountriesMap
	mapData: CountriesData;
	readonly apiKey = KEYS.geoCharts;
	readonly valueLabel = computed(() => this.isGroup() ? 'Total' : 'Flags');
	readonly minValue = 0;
	readonly maxValue = 1;
	readonly countryColor = '#4dd0e1';
	readonly selfCountryColor = '#ffee58';

	constructor(private readonly cdRef: ChangeDetectorRef) { }

	readonly isGroup = computed(() => this.input() instanceof Group);

	/**
	 * Pasar de un array IFlagUser[] a un objeto CountriesData
	 */
	private generateMap() {
		const isGroup = this.isGroup();
		this.mapData = this.list().reduce((result: CountriesData, flagUser: IFlagUser) => {
			const newCountry: CountriesData = {
				[flagUser.country.id.toUpperCase()]: {
					value: flagUser.items.some(user => user.isSelfCountry) ?
						null : flagUser.total,
					extra: isGroup ?
						flagUser.items.reduce((acc: CountryExtraData, userFlag: { isSelfCountry: boolean, times: number, user: User }) =>
							userFlag.isSelfCountry ? acc : Object.assign(acc, {
								[userFlag.user.username]: userFlag.times
							})
						, {})
						: undefined
				}
			};
			return Object.assign(result, newCountry);
		}, {});
	}

	mapLoaded() {
		this.loading = false;
		this.cdRef.detectChanges();
	}

	regionClicked(ev: ChartSelectEvent) {
		this.selectedCountry = ev.selected ? new Country(ev.country.toLowerCase()) : null;
		this.cdRef.detectChanges();
	}

	get groupItems() {
		return this.list().find(item => item.country.id === this.selectedCountry.id);
	}

	get userTimes() {
		const flagInfo = this.list().find(flagItem => flagItem.country.id === this.selectedCountry.id);
		return flagInfo.items[0].isSelfCountry ? null : flagInfo.total;
	}

	ngOnInit() {
		this.generateMap();
		this.mapLoaded();
	}

	errorLoadingMap() {
		this.loading = false;
		this.hasError = true;
		this.loadError.emit();
	}

}
