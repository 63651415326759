import { Component } from '@angular/core';
import { type NgForm, FormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { takeUntil, concatMap } from 'rxjs/operators';
import { PATHS } from '../../../app.constants';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import { SubscribableBaseComponent } from '../subscribable-base/subscribable-base.component';
import type { HttpErrorResponse } from '@angular/common/http';
import { UsersService } from '../../services/users.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
	selector: 'dflgr-login-modal',
	standalone: true,
	imports: [FormsModule, MatDialogModule, MatFormFieldModule, MatInputModule, RouterLink, MatProgressBarModule, MatButtonModule, MatIconModule],
	templateUrl: './login-modal.component.html',
	styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent extends SubscribableBaseComponent {

	isLogging = false;
	readonly recoverLink = [`/${PATHS.recover}`];
	readonly actionsAlignment = 'center';
	hasError = false;

	constructor(
		private readonly authProvider: AuthService,
		private readonly alertProvider: AlertService,
		private readonly userProvider: UsersService,
		public readonly dialogRef: MatDialogRef<LoginModalComponent, boolean>
	) { super(); }

	doLogin(loginForm: NgForm) {
		if (loginForm.valid) {
			this.isLogging = true;
			this.hasError = false;
			this.authProvider.login(
				loginForm.controls.user.value,
				loginForm.controls.pass.value,
				'web')
				.pipe(
					takeUntil(this.ngUnsubscribe),
					concatMap(() => this.userProvider.getFriendRequests())
				)
				.subscribe(
					() => { this.dialogRef.close(true); },
					(e: HttpErrorResponse | string) => {
						this.hasError = true;
						this.alertProvider.publish({
							msg: typeof e === 'string' ? e : 'Some error occurred!', action: 'Whoops', duration: 10000
						});
						this.isLogging = false;
					}
				);
		}
	}

}
