import { Component, input, HostBinding } from '@angular/core';
import { activeNavLinks } from './nav-icon.animations';

import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
	animations: [activeNavLinks],
	selector: 'dflgr-nav-icon',
	standalone: true,
	imports: [RouterLinkActive, RouterLink, MatIconModule, MatBadgeModule],
	templateUrl: './nav-icon.component.html',
	styleUrls: ['./nav-icon.component.scss']
})
export class NavIconComponent {
	@HostBinding('attr.role') readonly role = 'link';
	readonly icon = input.required<string>();
	readonly text = input<string>();
	readonly path = input<string | string[]>();
	readonly badge = input(0);
	readonly showTitle = input(true);

	get titleText() {
		return this.text() || '';
	}
}
