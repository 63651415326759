/* src/app/shared/components/country-flag/country-flag.component.scss */
.flag-utf {
  display: none;
}
@media (max-width: 599px) {
  .flag-utf {
    display: inline-block;
  }
}
.flag-container img {
  width: 1rem;
  vertical-align: baseline;
}
@media (max-width: 599px) {
  .flag-container img {
    display: none;
  }
}
/*# sourceMappingURL=country-flag.component.css.map */
