/**
 * Utf8 class: encode / decode between multi-byte Unicode characters
 * and UTF-8 multiple single-byte character encoding.
 * Adapted to ES6/Typescript by Jago MF.
 *
 * @author (c) Chris Veness 2002-2010
 */
class Utf8 {

	/**
	 * Encode multi-byte Unicode string into utf-8 multiple single-byte characters
	 * (BMP / basic multilingual plane only)
	 *
	 * Chars in range U+0080 - U+07FF are encoded in 2 chars, U+0800 - U+FFFF in 3 chars
	 *
	 * @param {String} strUni Unicode string to be encoded as UTF-8
	 * @returns {String} encoded string
	 */
	static encode(strUni: string): string {
		// use regular expressions & String.replace callback function for better efficiency
		// than procedural approaches
		let strUtf = strUni.replace(
			/[\u0080-\u07ff]/g,  // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
			(c) => {
				const cc = c.charCodeAt(0);
				return String.fromCharCode(0xc0 | cc >> 6, 0x80 | cc & 0x3f); }
			);
		strUtf = strUtf.replace(
			/[\u0800-\uffff]/g,  // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
			(c) => {
				const cc = c.charCodeAt(0);
				return String.fromCharCode(0xe0 | cc >> 12, 0x80 | cc >> 6 & 0x3F, 0x80 | cc & 0x3f); }
			);
		return strUtf;
	}

	/**
	 * Decode utf-8 encoded string back into multi-byte Unicode characters
	 *
	 * @param {String} strUtf UTF-8 string to be decoded back to Unicode
	 * @returns {String} decoded string
	 */
	decode(strUtf: string): string {
		// note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
		let strUni = strUtf.replace(
			/[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
			(c) => {  // (note parentheses for precence)
				const cc = ((c.charCodeAt(0) & 0x0f) << 12) | ((c.charCodeAt(1) & 0x3f) << 6) | ( c.charCodeAt(2) & 0x3f);
				return String.fromCharCode(cc); }
			);
		strUni = strUni.replace(
			/[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
			(c) => {  // (note parentheses for precence)
				const cc = (c.charCodeAt(0) & 0x1f) << 6 | c.charCodeAt(1) & 0x3f;
				return String.fromCharCode(cc); }
			);
		return strUni;
	}
}

/**
 * SHA-256 implementation in JavaScript.
 * Adapted to ES6/Typescript by Jago MF.
 *
 * @author (c) Chris Veness 2002-2010 | www.movable-type.co.uk
 * @see http://csrc.nist.gov/groups/ST/toolkit/secure_hashing.html
 * @see http://csrc.nist.gov/groups/ST/toolkit/examples.html
 */
export default class Sha256 {

	/**
	 * Generates SHA-256 hash of string
	 *
	 * @param {String} msg                String to be hashed
	 * @param {Boolean} [utf8encode=true] Encode msg as UTF-8 before generating hash
	 * @returns {String}                  Hash of msg as hex character string
	 */
	static hash(msg: string, utf8encode: boolean = true): string {

		// convert string to UTF-8, as SHA only deals with byte-streams
		if (utf8encode) { msg = Utf8.encode(msg); }

		// constants [§4.2.2]
		const K = [0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5,
				0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174,
				0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da,
				0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967,
				0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13, 0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85,
				0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070,
				0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3,
				0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2];
		// initial hash value [§5.3.1]
		const H = [0x6a09e667, 0xbb67ae85, 0x3c6ef372, 0xa54ff53a, 0x510e527f, 0x9b05688c, 0x1f83d9ab, 0x5be0cd19];

		// PREPROCESSING

		msg += String.fromCharCode(0x80);  // add trailing '1' bit (+ 0's padding) to string [§5.1.1]

		// convert string msg into 512-bit/16-integer blocks arrays of ints [§5.2.1]
		const l = msg.length / 4 + 2;  // length (in 32-bit integers) of msg + ‘1’ + appended length
		const N = Math.ceil(l / 16);   // number of 16-integer-blocks required to hold 'l' ints
		const M = new Array(N);

		for (let i = 0; i < N; i++) {
			M[i] = new Array(16);
			for (let j = 0; j < 16; j++) {  // encode 4 chars per integer, big-endian encoding
				M[i][j] = (msg.charCodeAt(i * 64 + j * 4) << 24) | (msg.charCodeAt(i * 64 + j * 4 + 1) << 16) |
							(msg.charCodeAt(i * 64 + j * 4 + 2) << 8) | (msg.charCodeAt(i * 64 + j * 4 + 3));
			} // note running off the end of msg is ok 'cos bitwise ops on NaN return 0
		}
		// add length (in bits) into final pair of 32-bit integers (big-endian) [§5.1.1]
		// note: most significant word would be (len-1)*8 >>> 32, but since JS converts
		// bitwise-op args to 32 bits, we need to simulate this by arithmetic operators
		M[N - 1][14] = ((msg.length - 1) * 8) / Math.pow(2, 32); M[N - 1][14] = Math.floor(M[N - 1][14]);
		M[N - 1][15] = ((msg.length - 1) * 8) & 0xffffffff;


		// HASH COMPUTATION [§6.1.2]

		const W = new Array(64); let a, b, c, d, e, f, g, h;
		for (let i = 0; i < N; i++) {

			// 1 - prepare message schedule 'W'
			for (let t = 0;  t < 16; t++) { W[t] = M[i][t]; }
			for (let t = 16; t < 64; t++) { W[t] = (Sha256.sigma1(W[t - 2]) + W[t - 7] + Sha256.sigma0(W[t - 15]) + W[t - 16]) & 0xffffffff; }

			// 2 - initialise working variables a, b, c, d, e, f, g, h with previous hash value
			a = H[0]; b = H[1]; c = H[2]; d = H[3]; e = H[4]; f = H[5]; g = H[6]; h = H[7];

			// 3 - main loop (note 'addition modulo 2^32')
			for (let t = 0; t < 64; t++) {
				const T1 = h + Sha256.Sigma1(e) + Sha256.Ch(e, f, g) + K[t] + W[t];
				const T2 = Sha256.Sigma0(a) + Sha256.Maj(a, b, c);
				h = g;
				g = f;
				f = e;
				e = (d + T1) & 0xffffffff;
				d = c;
				c = b;
				b = a;
				a = (T1 + T2) & 0xffffffff;
			}
			// 4 - compute the new intermediate hash value (note 'addition modulo 2^32')
			H[0] = (H[0] + a) & 0xffffffff;
			H[1] = (H[1] + b) & 0xffffffff;
			H[2] = (H[2] + c) & 0xffffffff;
			H[3] = (H[3] + d) & 0xffffffff;
			H[4] = (H[4] + e) & 0xffffffff;
			H[5] = (H[5] + f) & 0xffffffff;
			H[6] = (H[6] + g) & 0xffffffff;
			H[7] = (H[7] + h) & 0xffffffff;
		}

		return Sha256.toHexStr(H[0]) + Sha256.toHexStr(H[1]) + Sha256.toHexStr(H[2]) + Sha256.toHexStr(H[3]) +
				Sha256.toHexStr(H[4]) + Sha256.toHexStr(H[5]) + Sha256.toHexStr(H[6]) + Sha256.toHexStr(H[7]);
	}

	private static ROTR(n, x) { return (x >>> n) | (x << (32 - n)); }
	private static Sigma0(x) { return Sha256.ROTR(2,  x) ^ Sha256.ROTR(13, x) ^ Sha256.ROTR(22, x); }
	private static Sigma1(x) { return Sha256.ROTR(6,  x) ^ Sha256.ROTR(11, x) ^ Sha256.ROTR(25, x); }
	private static sigma0(x) { return Sha256.ROTR(7,  x) ^ Sha256.ROTR(18, x) ^ (x >>> 3);  }
	private static sigma1(x) { return Sha256.ROTR(17, x) ^ Sha256.ROTR(19, x) ^ (x >>> 10); }
	private static Ch(x, y, z) { return (x & y) ^ (~x & z); }
	private static Maj(x, y, z) { return (x & y) ^ (x & z) ^ (y & z); }

	/**
	 * Hexadecimal representation of a number.
	 * Note toString(16) is implementation-dependant, and
	 * in IE returns signed numbers when used on full words)
	 */
	private static toHexStr(n) {
		let s = '', v;
		for (let i = 7; i >= 0; i--) { v = (n >>> (i * 4)) & 0xf; s += v.toString(16); }
		return s;
	}
}
