import { Component, forwardRef, input, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, FormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import Country from '../../models/country.model';
import { FlagsService } from '../../services/flags.service';
import { SubscribableBaseComponent } from '../subscribable-base/subscribable-base.component';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { MatOptionModule } from '@angular/material/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

// PROVEER ACCESO AL MODELO AL VALOR DE ESTE COMPONENTE
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => SelectCountryComponent),
	multi: true
};
const CUSTOM_INPUT_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => SelectCountryComponent),
	multi: true,
};

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'dflgr-select-country',
	standalone: true,
	imports: [MatFormFieldModule, MatInputModule, MatAutocompleteModule, FormsModule, MatOptionModule, CountryFlagComponent, NgIf],
	templateUrl: './select-country.component.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_VALIDATOR],
	styleUrls: ['./select-country.component.scss']
})
export class SelectCountryComponent extends SubscribableBaseComponent implements OnInit, ControlValueAccessor, Validator {
	@HostBinding('attr.aria-label') readonly ariaLabel = 'Country Selector';
	@HostBinding('attr.role') readonly role = 'combobox';
	lang = 'en';
	countries: Country[];
	selected: Country;
	readonly placeholder = input('Country name');
	readonly label = input<string>();
	readonly excluded = input<Country>();

	constructor(private readonly flagsProvider: FlagsService) { super(); }

	// Foreign access to this component's value
	private onTouchedCallback: () => void = () => { };
	// Foreign access to this component's value
	private onChangeCallback: (val: Country) => void = () => { };

	ngOnInit() {
		this.flagsProvider.getCountriesList()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(list => this.countries = list);
	}

	get filtered(): Country[] {
		if (this.countries?.length) {
			const filterValue = this.selected
				? this.selected instanceof Country
					? this.selected.name
					: (this.selected as string).toLowerCase()
				: '';
			return this.countries.filter(country =>
				country.name?.toLowerCase()?.includes(filterValue)
			);
		}
		return [];
	}

	countrySelected({ option: { value: country } }: { option: { value: Country; } }) {
		this.selected = country;
		this.onChangeCallback(country);
	}

	isExcluded(country: Country): boolean {
		return this.excluded()?.id === country.id;
	}

	textTyped(input: HTMLInputElement) {
		const stillKeepsCountry = this.countries.find(country => input.value === country.name);
		if (!stillKeepsCountry) {
			this.onChangeCallback(null); // invalidates control
		}
	}

	displayFn(value: Country | string): string {
		return value instanceof Country ? value.name : value;
	}

	get isValid(): boolean {
		return this.selected instanceof Country;
	}

	/** VALIDAR */

	validate() {
		return this.isValid ? null : { validCountry: false };
	}

	/** PROVEER ACCESO AL MODELO AL VALOR DE ESTE COMPONENTE */

	// Set touched on blur
	onBlur() {
		this.onTouchedCallback();
	}

	// Write value passed in from a Form
	writeValue(value: Country) {
		this.selected = value;
	}

	// Register onChange callback
	registerOnChange(fn: (val: Country) => void) {
		this.onChangeCallback = fn;
	}

	// Register onTouched callback
	registerOnTouched(fn: () => void) {
		this.onTouchedCallback = fn;
	}

}
