@charset "UTF-8";

/* src/app/shared/components/header-app/header-app.component.scss */
:host {
  width: 100%;
  top: 0;
  z-index: 100;
}
.header-app {
  height: 44px;
}
.header-icon {
  margin-right: 8px;
}
.header-icon.mainlogo {
  background-image: url(/assets/icons/appicon-24x24.png);
  background-position: center;
  background-repeat: no-repeat;
}
.header-icon,
h1 {
  cursor: pointer;
}
h1 {
  flex: 0;
  display: inline-block;
  letter-spacing: normal !important;
  font-weight: 200 !important;
}
@media (max-width: 599px) {
  h1.hide-if-mobile {
    display: none;
  }
}
h2 {
  flex: 1 1 auto;
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 600px) {
  h2::before {
    content: "\2022";
    font-weight: 200;
    font-size: 16px;
    margin: 0 2px 0 6px;
  }
}
.action-unlogged span {
  text-transform: uppercase;
}
/*# sourceMappingURL=header-app.component.css.map */
