import { Component, input, output, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import User from '../../models/user.model';
import Group from '../../models/group.model';
import Country from '../../models/country.model';
import { FriendActionsComponent } from '../friend-actions/friend-actions.component';
import { MatMenuModule } from '@angular/material/menu';
import { ExtendedMenuComponent } from '../extended-menu/extended-menu.component';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgStyle, DatePipe, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
	selector: 'dflgr-user-card',
	standalone: true,
	imports: [MatCardModule, NgStyle, RouterLink, MatIconModule, CountryFlagComponent, ExtendedMenuComponent, MatMenuModule, FriendActionsComponent, DatePipe, NgIf],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './user-card.component.html',
	styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent {

	readonly user = input.required<User>();
	readonly group = input<Group>(null);
	readonly showFriendActions = input(false);
	readonly isFriendRequest = input(false);
	readonly statusChanged = output<void>();
	readonly removeFromGroup = output<void>();
	readonly makeAdmin = output<void>();

	constructor(
		private readonly authProvider: AuthService
	) { }

	get userIsSelf() {
		return this.user()?.id === this.authProvider.localUser.id;
	}

	get userLink() {
		return this.user()?.isBlocked ? null : this.user()?.link ?? [];
	}

	get userIsGroupAdmin() {
		return this.user().id === this.group()?.adminId;
	}

	get selfIsGroupAdmin() {
		return this.group()?.adminId === this.authProvider.localUser.id;
	}

	get defaultAvatarBgStyle() {
		return this.user()?.defaultAvatarBgSrc;
	}

	getUserCountryLink(country: Country) {
		return this.user()?.getUserCountryLink(country) ?? [];
	}

}
