/* src/app/shared/components/static-footer/static-footer.component.scss */
hr {
  margin-top: 1.4em;
  margin-bottom: 1em;
}
footer {
  text-align: center;
  font-size: 0.9em;
  opacity: 0.6;
  padding-bottom: 1em;
}
footer p {
  margin: 0.5em auto;
}
footer a {
  color: #009faf;
}
/*# sourceMappingURL=static-footer.component.css.map */
