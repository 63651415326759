interface HTMLInputCreatedElement extends HTMLInputElement {
	// Not standard but helps in manipulation of element when removal is needed
	removed?: boolean;
}

export default abstract class Utils {

	/**
	 * Prompts to choose one or more files from filesystem, converts to base64 (in background), and returns base64 when conversion ends.
	 * If `multiple` is set to `true`, returned promise will contain an array (instead of a string).
	 * @param {number} maxFileSize Maximum size of file (1048576 = 1MB).
	 * @param {boolean} multiple Indicates whether to accept one or more files.
	 * @param {string} contentType MIME type of media to require from filesystem (can be `'image/*'`, etc).
	 * @param {string} source (For mobile devices only) If set to 'camera', tells device to capture image from camera.
	 * @returns {Promise} `Promise` that resolves with a `Array<string>` (if `multiple`) or else with a `string`; rejects with error string.
	 */
	static onClickSendMedia(
		maxFileSize: number,
		multiple = false,
		contentType = '*/*',
		source?: string
	): Promise<string | string[]> {
		return new Promise((resolve, reject) => {
			// Create temporary input element
			const element: HTMLInputCreatedElement = document.createElement('input');

			// Assign properties to element.
			element.removed = false;
			element.type = 'file';
			element.id = 'addAttach';
			element.multiple = multiple;
			element.accept = contentType;
			if (source) { // this condition is necessary for iOS
				element.capture = source;
			}

			// Hide element
			element.style.display = 'none';

			// Register what happens when element content has changed
			element.addEventListener('change', ({target}) => {
				const filesObj: File[] = Array.from((target as HTMLInputElement).files);
				const allFilesFit = filesObj.every(file => file.size <= maxFileSize);

				if (!allFilesFit) {
					reject(`Files cannot be bigger than ${maxFileSize / (1024 * 1024)}MB.`);
				}

				const processes = filesObj.map((fileObj) => new Promise((resolveInner) => {
					const webworkerReader = new FileReader();

					webworkerReader.onload = () => {
						const base64Str = webworkerReader.result;
						resolveInner(base64Str);
					};

					webworkerReader.readAsDataURL(fileObj);
				}));

				Promise.all(processes).then((values: string[]) => {
					resolve(multiple ? values : values[0]);
				});

				// Once file content is retrieved, destroy temporary input element
				element.onblur = null;
				if (!element.removed) {
					element.removed = true;
					element.remove();
				}
			});

			// Append element to DOM.
			document.querySelector('body').appendChild(element);

			// Force click
			element.click();
		});
	}

	/**
	 * Turns a number into a 2 digit string
	 */
	static fix2digit(input: number | string) {
		return ('0' + input).slice(-2);
	}

	/**
	 * Parses a date from a string given a RegExp
	 * @param rawDate String with a date
	 * @param datePattern Regexp that can resolve date figures from a string
	 */
	static getDateFromUTCString(rawDate: string, datePattern: RegExp) {
		const [, year, month, day, hour, min, sec ] = datePattern.exec(rawDate);
		try {
			return new Date(Date.UTC(+year, +month - 1, +day, +hour, +min, +sec, 0));
		} catch (error) {
			return null;
		}
	}

	static getUrlDate(date: Date): string {
		return `${
			date.getUTCFullYear()}${Utils.fix2digit(date.getUTCMonth() + 1)}${
			Utils.fix2digit(date.getUTCDate())}${Utils.fix2digit(date.getUTCHours())}${
			Utils.fix2digit(date.getUTCMinutes())}${Utils.fix2digit(date.getUTCSeconds())
		}`;
	}

	public static stringToUrl(string: string): string {
		const a = 'æøåàáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
		const b = 'aoaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string.toString().toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w-]+/g, '') // Remove all non-word chars
			.replace(/--+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	}

}
