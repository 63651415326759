import { Component, input, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { Title } from '@angular/platform-browser';
import Country from '../../models/country.model';
import { GENERAL } from '../../../app.constants';
import { HeaderService } from '../../services/header.service';
import type { IMenuOptions, IMenuFilter } from '../../services/header.interfaces';

@Component({
	selector: 'dflgr-header-handler',
	standalone: true,
	template: '',
	styles: ['']
})
export class HeaderHandlerComponent implements OnInit, OnChanges {

	readonly title = input<string>();
	readonly flag = input<Country>();
	readonly backButton = input<boolean | string>(false);
	readonly options = input<IMenuOptions[]>();
	readonly filters = input<IMenuFilter[]>();
	readonly sorters = input<IMenuFilter[]>();

	constructor(
		private readonly headerProvider: HeaderService,
		private readonly appTitle: Title,
	) {
		this.setTitle();
	}

	ngOnInit() {
		this.setTitleAndHeader();
	}

	private setTitle(title?: string): void {
		this.appTitle.setTitle(`${GENERAL.app.title}${ title ? ` | ${title}` : ''}`);
	}

	private setTitleAndHeader(): void {
		this.setTitle(this.title());
		this.setRemoteHeader();
	}

	private setRemoteHeader() {
		this.headerProvider.setHeader(this.title() || this.flag() || this.filters() ? {
			text: this.title(),
			backButton: this.backButton(),
			country: this.flag(),
			filters: this.filters(),
			sorters: this.sorters(),
			actions: this.options()
		} : null);
	}

	ngOnChanges({ flag, title, options, filters }: { flag: SimpleChange, title: SimpleChange, options: SimpleChange, filters: SimpleChange }) {
		if ((flag && !flag.isFirstChange()) || (title && !title.isFirstChange())
			|| (options && !options.isFirstChange()) || (filters && !filters.isFirstChange())) {
			this.setTitleAndHeader();
		}
	}

}
