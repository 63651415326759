<ng-template #filterSorterItems let-item>
	<button [disabled]="item.disabled" mat-menu-item [role]="item.singleChoice ? 'menuitemradio' : 'menuitemcheckbox'" class="ext-filter" (click)="item.callback($event)">
		<mat-icon>
			{{ item.singleChoice
			? item.selected ? 'radio_button_checked' : 'radio_button_unchecked'
			: item.selected ? 'check_box' : 'check_box_outline_blank' }}
		</mat-icon>
		<span>{{item.text}}</span>
	</button>
</ng-template>
@if (filters()?.length) {
	<button mat-icon-button [matMenuTriggerFor]="filterMenu" class="filter-trigger" aria-label="Filter">
		<mat-icon>visibility</mat-icon><span id="filter-label" class="cdk-visually-hidden">Show by</span>
	</button>
	<mat-menu #filterMenu="matMenu" [overlapTrigger]="false" aria-labelledby="filter-label">
		@for (filter of filters(); track filter.text) {
			<ng-template [ngTemplateOutlet]="filterSorterItems" [ngTemplateOutletContext]="{ $implicit: filter }"/>
		}
	</mat-menu>
}
@if (sorters()?.length) {
	<button mat-icon-button [matMenuTriggerFor]="sortMenu" class="sorter-trigger" aria-label="Sort">
		<mat-icon>filter_list</mat-icon><span id="sort-label" class="cdk-visually-hidden">Sort by</span>
	</button>
	<mat-menu #sortMenu="matMenu" [overlapTrigger]="false" aria-labelledby="sort-label">
		@for (sorter of sorters(); track sorter.text) {
			<ng-template [ngTemplateOutlet]="filterSorterItems" [ngTemplateOutletContext]="{ $implicit: sorter }"/>
		}
	</mat-menu>
}
@if (options()?.length) {
	@for (item of (options() | viewableActions); track (item.icon + item.text)) {
		<button mat-icon-button [attr.aria-label]="item.text" [class.danger]="item.dangerColor" (click)="item.callback()">
			<mat-icon>{{item.icon}}</mat-icon>
		</button>
	}
}
@if (hasOverflow) {
	<button mat-icon-button aria-label="Actions menu" [matMenuTriggerFor]="menu" class="group-actions">
		<mat-icon>more_vert</mat-icon><span class="cdk-visually-hidden">Actions menu</span>
	</button>
}
<mat-menu #menu="matMenu" [overlapTrigger]="false">
	@if (letContent()) {
		<ng-content #hasChildren/>
	} @else {
		@for (item of (options() | viewableActions:true); track (item.icon + item.text)) {
			<button mat-menu-item class="ext-item" [class.danger]="item.dangerColor" (click)="item.callback()">
				<mat-icon>{{item.icon}}</mat-icon><span>{{item.text}}</span>
			</button>
		}
	}
</mat-menu>
