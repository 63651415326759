import { GENERAL, PATHS } from '../../app.constants';
import Utils from '../app.utils';

const explodeString = (arrayableString: string |string[]) => arrayableString
	? Array.isArray(arrayableString)
		? arrayableString
		: arrayableString.length
			? arrayableString.split(',')
			: [arrayableString]
	: [];

export default class GroupModel {
	id: string;
	adminId: string;
	name: string;
	memberIds?: string[];
	usernames?: string[];
	avatar?: string;

	constructor(id: string, name?: string) {
		this.id = id;
		if (name) {
			this.name = name;
		}
	}

	initialize(
		adminId: string,
		name: string,
		rawMemberIds?: string | string[],
		usernames?: string | string[],
		avatar?: string
	) {
		this.adminId = adminId.toString();
		this.name = name;
		this.memberIds = explodeString(rawMemberIds);
		this.usernames = explodeString(usernames);
		this.avatar = avatar;
		return this;
	}

	get link(): string[] {
		return [`/${GENERAL.handlers.groups.path}`, String(this.id), Utils.stringToUrl(this.name)];
	}

	get avatarSrc(): string {
		if (this.avatar) {
			return PATHS.avatars + this.avatar;
		}

		return this.defaultAvatarSrc;
	}

	private get defaultAvatarSrc() {
		return `/${PATHS.images}/default_group.png`;
	}

	get defaultAvatarBgSrc() {
		return { 'background-image': `url(${this.defaultAvatarSrc})` };
	}
}

export interface GroupRaw {
	id: string;
	admin: string;
	name: string;
	memberids?: string[];
	usernames?: string[];
	avatar?: string;
}
