<h3 mat-dialog-title>Crop your avatar</h3>
<mat-dialog-content>
	@if (isAvatar) {
		<p class="infotext">Flag Events might not display your new avatar until you log in again</p>
	}
	<dflgr-cropper #cropperContainer
		[cropperOptions]="config"
		[imageUrl]="image"/>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onAcceptCrop()">Crop</button>
	<button mat-button color="secondary" [mat-dialog-close]="null">Cancel</button>
</mat-dialog-actions>
