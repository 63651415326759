/* src/app/flags/components/add-flag-modal/add-flag-modal.component.scss */
.center-block {
  text-align: center;
}
.bottom-space {
  margin-bottom: 15px;
}
.country-joiner {
  display: flex;
  flex-flow: row wrap;
}
.full-datetime .fake-label {
  margin-bottom: -15px;
}
.now-joiner {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
}
.now-joiner .input-datetime {
  margin-left: 10px;
  width: 190px;
}
.form-event-pic {
  margin-bottom: 12px;
}
.event-pic-img {
  max-width: 100%;
  max-height: 250px;
}
input[type=datetime-local] {
  max-height: 18px;
}
.full-width {
  width: 100%;
}
@-moz-document url-prefix() {
  input[type=datetime-local] {
    max-height: 14px !important;
  }
}
.flag-action {
  display: flex;
  align-items: center;
}
/*# sourceMappingURL=add-flag-modal.component.css.map */
