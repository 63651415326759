/* src/app/shared/components/user-country/user-country.component.scss */
:host {
  display: block;
}
.card-header a {
  text-decoration: none;
  color: inherit;
}
.user-countries-times {
  text-align: end;
}
.user-countries-times .times {
  font-style: italic;
  font-size: x-large;
}
.user-countries-times a.times {
  text-decoration-line: underline;
  text-decoration-style: dotted;
}
.user-countries-times .group-times .group-total {
  margin-top: 5px;
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}
.user-countries-times .group-times .group-total span {
  display: inline-block;
  white-space: nowrap;
}
.user-countries-times .group-times .group-total span.times {
  text-decoration-line: underline;
  text-decoration-style: dotted;
}
.user-countries-times .group-times .group-total span.times-count {
  margin-left: 0.3rem;
}
.user-countries-times .group-times .group-users {
  display: flex;
  flex-flow: column nowrap;
  text-align: right;
}
.user-countries-times .group-times a.times {
  font-size: large;
}
/*# sourceMappingURL=user-country.component.css.map */
