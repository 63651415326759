import Cropper from 'cropperjs';
import { Component, viewChild, ElementRef, input, model, output, ViewEncapsulation } from '@angular/core';


interface ImageCropperSetting {
	width: number;
	height: number;
}

interface ImageCropperResult {
	imageData: Cropper.ImageData;
	cropData: Cropper.CropBoxData;
	blob?: Blob;
	dataUrl?: string;
}

/**
 * Angular implementation of Cropper.js 'forked' from https://github.com/matheusdavidson/angular-cropperjs
 */
@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'dflgr-cropper',
	standalone: true,
	imports: [],
	templateUrl: './cropper.component.html',
	styleUrls: ['./cropper.component.scss']
})
export class CropperComponent {

	readonly image = viewChild<ElementRef>('image');

	readonly imageUrl = input.required<string>();
	readonly settings = input<ImageCropperSetting>();
	readonly cropbox = input<Cropper.CropBoxData>();
	readonly loadImageErrorText = input<string>();
	readonly cropperOptions = model<Cropper.Options>({});

	readonly export = output<ImageCropperResult>();
	readonly ready = output<boolean>();

	public isLoading = true;
	public cropper: Cropper;
	public imageElement: HTMLImageElement;
	public loadError: boolean;

	/**
	 * Image loaded
	 * @param ev
	 */
	imageLoaded(ev: Event) {
		// Unset load error state
		this.loadError = false;

		// Setup image element
		const image = ev.target as HTMLImageElement;
		this.imageElement = image;

		// Add crossOrigin?
		if (this.cropperOptions().checkCrossOrigin) {
			image.crossOrigin = 'anonymous';
		}

		// Image on ready event
		image.addEventListener('ready', () => {
			// Emit ready
			this.ready.emit(true);

			// Unset loading state
			this.isLoading = false;

			// Validate cropbox existance
			if (this.cropbox) {

				// Set cropbox data
				this.cropper.setCropBoxData(this.cropbox());
			}
		});

		// Setup aspect ratio according to settings
		let aspectRatio = NaN;
		if (this.settings()) {
			const { width, height } = this.settings();
			aspectRatio = width / height;
		}

		// Set crop options
		// extend default with custom config
		this.cropperOptions.set(Object.assign({
			aspectRatio,
			movable: false,
			scalable: false,
			zoomable: false,
			viewMode: 1,
			checkCrossOrigin: true
		} as Cropper.Options, this.cropperOptions));

		// Set cropperjs
		this.cropper = new Cropper(image, this.cropperOptions());
	}

	/**
	 * Image load error
	 */
	imageLoadError() {
		// Set load error state
		this.loadError = true;

		// Unset loading state
		this.isLoading = false;
	}

}
