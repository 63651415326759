/* src/app/shared/components/extended-menu/extended-menu.component.scss */
mat-icon {
  color: rgba(0, 0, 0, 0.7);
}
.ext-item.danger,
.ext-item.danger .mat-icon,
::ng-deep .ext-item.danger,
::ng-deep .ext-item.danger .mat-icon {
  color: red;
}
/*# sourceMappingURL=extended-menu.component.css.map */
