@if (friend()?.isFriend) {
	<button mat-button (click)="updateFriendStatus(ACTION.BLOCKUSER)" color="warn"><mat-icon>block</mat-icon> Block</button>
	<button mat-button (click)="updateFriendStatus(ACTION.REMOVEFRIEND)" color="warn"><mat-icon>close</mat-icon> Unfriend</button>
} @else if (friend()?.isBlocked) {
	<button mat-button (click)="updateFriendStatus(ACTION.REMOVEFRIEND)" color="warn"><mat-icon>block</mat-icon> Unfriend</button>
	<button mat-button (click)="updateFriendStatus(ACTION.ADDFRIEND)" color="primary"><mat-icon>person_add</mat-icon> Unblock and add friend</button>
} @else if (friend()?.isNotFriend) {
	@if (friend().isPending) {
		<button mat-button (click)="updateFriendStatus(ACTION.REMOVEFRIEND)" color="warn"><mat-icon>close</mat-icon> Cancel request</button>
	}
	@if (!friend().isPending) {
		<button mat-button (click)="updateFriendStatus(ACTION.ADDFRIEND)" color="primary">
			<mat-icon>person_add</mat-icon>
			<span>@if (isFriendRequest()) { Confirm } @else { Add friend }</span>
		</button>
	}
	@if (isFriendRequest()) {
		<button mat-button (click)="updateFriendStatus(ACTION.REMOVEFRIEND)">Ignore</button>
	}
	<button mat-button (click)="updateFriendStatus(ACTION.BLOCKUSER)" color="warn">
		<mat-icon>block</mat-icon> Block
	</button>
}
