<mat-card>
	<mat-card-header class="card-header">
		<mat-card-title class="moderate-card-title">
			<a [routerLink]="userFlag()?.country.link">
				<dflgr-country-flag [country]="userFlag()?.country"/>
			</a>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content class="user-countries-times">
		@if (isGroup()) {
			<mat-accordion class="group-times">
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title class="group-total">
							<span class="times-count">Total</span>
							<span class="times times-count" [style.text-decoration-color]="userFlag()?.total | hotnessColor">&times;{{userFlag()?.total}}</span>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-panel-description class="group-users">
						@for (item of userFlag()?.items; track item) {
							<div class="group-users-times">
								<span>{{item.user.username}} </span>
								<a [routerLink]="getUserCountryLink(item.user, userFlag()?.country)" class="times" title="Flags of this country for {{item.user.username}}">&times;{{item.times}}</a>
							</div>
						}
					</mat-panel-description>
				</mat-expansion-panel>
			</mat-accordion>
		} @else {
			<div class="user-total">
				<a [routerLink]="getUserCountryLink(userFlag()?.items[0].user, userFlag()?.country)" class="times"
				[style.text-decoration-color]="userFlag()?.items[0].times | hotnessColor">&times;{{userFlag()?.items[0].times}}</a>
			</div>
		}
	</mat-card-content>
</mat-card>
