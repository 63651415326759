import { Component, HostBinding, input, computed } from '@angular/core';
import type { IFlagUser } from '../../services/flags.interfaces';
import User from '../../models/user.model';
import Group from '../../models/group.model';
import { UserCountryComponent } from '../user-country/user-country.component';


@Component({
	selector: 'dflgr-user-country-list',
	standalone: true,
	imports: [UserCountryComponent],
	templateUrl: './user-country-list.component.html',
	styleUrls: ['./user-country-list.component.scss']
})
export class UserCountryListComponent {

	@HostBinding('class.user-countries-container') readonly classUserCountriesContainer = true;

	readonly userFlags = computed(() =>
		this.list().filter((userFlag: IFlagUser) =>  !userFlag.items.every(item => item.isSelfCountry))
	);

	readonly list = input.required<IFlagUser[]>();

	readonly input = input<Group | User>();

	get isGroup(): boolean {
		return this.input() instanceof Group; // this.userFlags.some((userFlag) => userFlag.users.length > 1);
	}

}
