@if (loading) {
	<div class="spinner-container"><mat-spinner/></div>
} @else if (hasError) {
	<span>Error loading map!</span>
} @else {
	<countries-map
		[data]="mapData"
		[apiKey]="apiKey"
		[showCaption]="false"
		[minValue]="minValue"
		[maxValue]="maxValue"
		[valueLabel]="valueLabel()"
		[maxColor]="countryColor"
		[exceptionColor]="selfCountryColor"
		[autoResize]="true"
		(chartSelect)="regionClicked($event)"
		(chartError)="errorLoadingMap()"
		(chartReady)="mapLoaded()"
		/>
	@if (selectedCountry) {
		<div class="caption-times-totals">
			<!-- Emulation of the <dflgr-user-country> card -->
			<mat-card class="card-times">
				<mat-card-header class="card-header">
					<mat-card-title class="moderate-card-title">
						<a [routerLink]="selectedCountry.link">
							<dflgr-country-flag [country]="selectedCountry"/>
						</a>
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="user-countries-times">
					@if (isGroup()) {
						<div class="group-times">
							<div class="group-total">
								<div class="group-flag-total">Group total: &times;{{groupItems.total}}</div>
								@for (flagUser of groupItems.items; track flagUser) {
									<div class="group-flag-item">
										<a [routerLink]="flagUser.user.link">{{flagUser.user.username}}</a>:
										@if (flagUser.isSelfCountry) {
											<span>Self country</span>
										} @else {
											<span>&times;{{flagUser.times}}</span>
										}
									</div>
								}
							</div>
						</div>
					} @else {
						<div class="user-total">
							<span>@if (userTimes) { Times: &times;{{userTimes}} } @else { Self country }</span>
						</div>
					}
				</mat-card-content>
			</mat-card>
		</div>
	}
}
