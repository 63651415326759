@if (showHR()) {<hr>}
<footer>
	<p>Made with ❤️ by the {{title}} team</p>
	<p><small>
		<a [routerLink]="'/' + SECTIONS.about">About {{title}}</a> |
		<a [routerLink]="'/' + SECTIONS.help">Help / FAQ</a> |
		<a [routerLink]="'/' + SECTIONS.terms">Terms &amp; Conditions</a> |
		<a [routerLink]="'/' + SECTIONS.privacy">Privacy Policy</a>
	</small></p>
</footer>
