import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { type Observable, Subject, of as ObservableOf } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import User, { type UserRaw } from '../models/user.model';
import { AUTH as AuthURLs, USERS as UsersURLs } from '../../api.constants';
import { ConnectedService } from './connected.service';
import { StorageService } from './storage.service';
import Sha256 from '../../../assets/sha256';

@Injectable({ providedIn: 'root' })
export class AuthService extends ConnectedService {

	private _localUser: User;
	private _localSession: string;

	private readonly loginRequestedSub = new Subject<string>();
	private readonly loggedStatusSub = new Subject<boolean>();

	constructor(
		private readonly httpConn: HttpClient,
		private readonly storageService: StorageService
	) {
		super();
	}

	get isLogged() {
		return !!this.localUser && !!this.localSession;
	}

	get localUser(): User {
		return this._localUser || this.storageService.localUser;
	}

	get localUserLinkStr(): string {
		return this.localUser?.linkStr ?? '';
	}

	get localSession(): string {
		return this._localSession || this.storageService.localSession;
	}

	get localLanguage(): string {
		return this.storageService.localLanguage;
	}

	private updateUser(user: User) {
		if (user) {
			this._localUser = user;
		}
	}

	onLoggedChanged() {
		return this.loggedStatusSub.asObservable();
	}

	requestLogin(nextPath?: string) {
		this.loginRequestedSub.next(nextPath);
	}

	onLoginRequested() {
		return this.loginRequestedSub.asObservable();
	}

	/**
	 * Creates a session.
	 * @param username Username of user logging in
	 * @param pass Plain text password
	 * @param type Type of login. For website is `web`
	 * @returns Session ID and logged user details
	 */
	login(username: string, pass: string, type?: string): Observable<{ sessid: string; user: User; }> {
		const { endp, meth } = AuthURLs.LOGIN;
		const endpoint = this.makeEndpoint(endp);

		const hashedPass = Sha256.hash(pass);
		const body = { username, hashedpass: hashedPass, type };

		return this.httpConn.request<UserRaw>(meth, endpoint, { body }).pipe(
			map((res) => ({
				sessid: res.sessid,
				user: new User(res.id).initialize(
					res.username,
					res.name,
					res.surname,
					res.birthdate,
					res.gender,
					res.country1,
					res.country2,
					res.avatar,
					null,
					res.bio
				)
			})),
			tap(({ user, sessid }: { user: User, sessid: string }) => {
					if (user && sessid) {
						this._localUser = user;
						this._localSession = sessid;
						this.storageService.storeSession(sessid, user);
						this.storageService.userChanged.subscribe(this.updateUser.bind(this));
						this.loggedStatusSub.next(true);
					}
				}),
			catchError((e: HttpErrorResponse) => {
				return this.handleError(e.status === 409
					? 'You have not verified your email yet! Check your email for the activation code.'
					: e, null, true
				);
			})
		);
	}

	killSession() {
		this.storageService.userChanged.next(null);
		this.storageService.userChanged.complete();

		this._localUser = null;
		this._localSession = null;
		this.loggedStatusSub.next(false);
		this.storageService.endSession();
	}

	/**
	 * Logs out of the session. Returns a 'session killer' function to be run.
	 * @returns Function to be run in order to fully finalize session, after navigation - if any.
	 */
	logout(timeoutLimit = 2000): Observable<() => void> {
		const { endp, meth } = AuthURLs.LOGOUT;
		const endpoint = this.makeEndpoint(endp);

		return this.httpConn.request(meth, endpoint).pipe(
			timeout(timeoutLimit),
			map(() => this.killSession.bind(this)),
			catchError(() => ObservableOf(this.killSession.bind(this)))
		);
	}

	/**
	 * Logs out of the session. Returns a 'session killer' function to be run if successful.
	 * @returns Function to be run if successful in order to fully finalize session, after navigation - if any.
	 */
	deleteAccount(pass: string): Observable<() => void> {
		const { endp, meth } = UsersURLs.DELETEACCOUNT;
		const endpoint = this.makeEndpoint(endp);

		const hashedpass = Sha256.hash(pass);
		const params = this.makeParams({ hashedpass });

		return this.httpConn.request(meth, endpoint, { params , observe: 'response'}).pipe(
			map((thing) => {
				console.log(thing);
				return this.killSession.bind(this);
			}),
			catchError(e => this.handleError(e, null))
		);
	}

	editPassword(oldhashedpass: string, newhashedpass: string) {
		const { endp, meth } = AuthURLs.EDITPASS;
		const endpoint = this.makeEndpoint(endp);
		const body = { oldhashedpass, newhashedpass };

		return this.httpConn.request(meth, endpoint, { body, observe: 'response' }).pipe(
			map(res => res.status === 204),
			catchError(e => this.handleError(e, null))
		);
	}

}
