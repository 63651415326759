<h2 mat-dialog-title>{{dialogData?.title}}</h2>
<mat-dialog-content>
	<p>{{dialogData?.text}}</p>
</mat-dialog-content>
<mat-dialog-actions>
	@for (action of dialogData?.actions; track action) {
		<button mat-button
		[mat-dialog-close]="action.code">{{action.label}}</button>
	}
</mat-dialog-actions>
