import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { AlertService } from '../../services/alert.service';

@Component({
	selector: 'dflgr-cookies-alert',
	standalone: true,
	template: ''
})
export class CookiesAlertComponent implements OnInit {

	constructor(
		private readonly storageService: StorageService,
		private readonly alertService: AlertService
	) { }

	private checkCookiesAccepted() {
		if (!this.storageService.hasAccepted) {
			Promise.resolve().then(() => this.alertService.publish({
				msg: `If you continue to use this webapp by signing up or in, you accept to have cookies stored in your device.
					Read more in our info pages.`,
				action: 'I UNDERSTAND',
				callback: () => { this.storageService.hasAccepted = true; }
			}));
		}
	}

	ngOnInit() {
		this.checkCookiesAccepted();
	}

}
