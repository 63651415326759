<!-- CROPPER WRAPPER -->
<div class="cropper-wrapper">

	<!-- LOADING -->
	@if (isLoading) {
		<div class="loading-block">
			<div class="spinner"></div>
		</div>
	}

	<!-- LOAD ERROR -->
	@if (loadError) {
		<div class="alert alert-warning">{{ loadImageErrorText() }}</div>
	}

	<!-- CROPPER -->
	<div class="cropper">
		<img #image alt="image" [src]="imageUrl()" (load)="imageLoaded($event)" (error)="imageLoadError()" />
	</div>

</div>
