import { Component, HostBinding, input, output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { of as ObservableOf } from 'rxjs';
import { takeUntil, concatMap } from 'rxjs/operators';
import FlagEvent, { VERB } from '../../models/flag-event.model';
import { SubscribableBaseComponent } from '../subscribable-base/subscribable-base.component';
import { FLAGEVENTS } from '../../../app.constants';
import { AuthService } from '../../services/auth.service';
import { FlagsService } from '../../services/flags.service';
import { SimplePromptModalComponent } from '../simple-prompt-modal/simple-prompt-modal.component';
import type { ISimplePromptData } from '../simple-prompt-modal/simple-prompt-modal.interfaces';
import { AlertService } from '../../services/alert.service';
import { AddFlagModalComponent, AddFlagModalSize } from '../../../flags/components/add-flag-modal/add-flag-modal.component';
import type { IAddFlagData } from '../../../flags/components/add-flag-modal/add-flag-modal.interfaces';
import { MatMenuModule } from '@angular/material/menu';
import { ExtendedMenuComponent } from '../extended-menu/extended-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { NgStyle, NgTemplateOutlet, NgClass, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'dflgr-flag-event',
	standalone: true,
	imports: [MatCardModule, RouterLink, NgStyle, CountryFlagComponent, NgTemplateOutlet, NgClass, MatButtonModule, MatIconModule, ExtendedMenuComponent, MatMenuModule, DatePipe],
	templateUrl: './flag-event.component.html',
	styleUrls: ['./flag-event.component.scss']
})
export class FlagEventComponent extends SubscribableBaseComponent {
	@HostBinding('tabindex') readonly tabIndex = 0;
	@HostBinding('attr.aria-label') readonly ariaLabel = 'Flag event';
	@HostBinding('class.card') readonly classCard = true;
	readonly flagEvent = input.required<FlagEvent>();
	readonly longText = input(false);
	readonly flagEventDeleted = output<void>();

	constructor(
		private readonly authProvider: AuthService,
		private readonly alertProvider: AlertService,
		private readonly modalHandler: MatDialog,
		private readonly cdRef: ChangeDetectorRef,
		private readonly flagsProvider: FlagsService,
	) {
		super();
	}

	private static verbTranslator(verb: VERB): string {
		switch (verb) {
			case VERB.REST: return FLAGEVENTS.verbs.VERBGETRESTTIMES;
			case VERB.FIRST: default: return FLAGEVENTS.verbs.VERBGETFIRSTTIME;
		}
	}

	get userLink() {
		return this.flagEvent()?.user.link;
	}

	requestDelFlag() {
		this.modalHandler.open<SimplePromptModalComponent<boolean>, ISimplePromptData<boolean>, boolean>(SimplePromptModalComponent, {
			data: {
				title: 'Delete Flag Event?',
				text: 'Are you sure?',
				actions: [{code: true, label: 'Sure!'}, { code: false, label: 'Nope' }]
			}
		}).afterClosed()
			.pipe(
				concatMap(choice => choice ? this.flagsProvider.deleteFlagEvent(this.flagEvent()) : ObservableOf(true)),
				takeUntil(this.ngUnsubscribe),
			).subscribe(aborted => { // should be true only if user canceled/aborted prompt
				if (!aborted) {
					this.flagEventDeleted.emit();
				}
			}, () => this.alertProvider.publish({
				msg: 'Some error prevented event from being deleted!',
				action: 'Oh no!', duration: 4000
			}));
	}

	requestEditFlag() {
		const config: MatDialogConfig<IAddFlagData> = AddFlagModalSize();
		config.data = { event: this.flagEvent() };
		this.modalHandler.open<AddFlagModalComponent, IAddFlagData, FlagEvent>(AddFlagModalComponent, config);
	}

	get userIsSelf(): boolean {
		return this.flagEvent()?.user.id === this.authProvider.localUser.id;
	}

	get haveLiked(): boolean {
		return this.flagEvent().likes.includes(this.authProvider.localUser.id);
	}

	get verb1(): string {
		return FlagEventComponent.verbTranslator(this.flagEvent()?.verbCodes.verb1);
	}

	get verb2(): string {
		return (!this.flagEvent()?.verbCodes.verb2 ||
			this.flagEvent()?.verbCodes.verb1 === this.flagEvent()?.verbCodes.verb2)
				? '' : FlagEventComponent.verbTranslator(this.flagEvent()?.verbCodes.verb2);
	}

	get canShare() {
		return !!(navigator.share);
	}

	get defaultAvatarBgStyle() {
		return this.flagEvent()?.user.defaultAvatarBgSrc;
	}

	/**
	 * Uses Web Share API to try to share event URL.
	 * @requires navigator.share
	 */
	doShare() {
		const { user, country1, country2 } = this.flagEvent();
		const country2text = country2 ? ` and ${this.verb2} ${country2.name}` : '';
		navigator.share({
			title: `${user.username} ${this.verb1} ${country1.name}${country2text}`,
			url: this.flagEvent().plainWhenlink
		}).then(() => { /* shared */ }).catch(() => { /* could not share */ });
	}

	preCacheEvent() {
		this.flagsProvider.resolvedEvent = this.flagEvent();
	}

	requestToggleLike(like: boolean) {
		this.flagsProvider.likeEvent(this.flagEvent(), like).subscribe(newStatus => {
			if (newStatus !== null) {
				if (like) {
					this.flagEvent().likes.push(this.authProvider.localUser.id);
				} else {
					const myLikeIndex = this.flagEvent().likes.findIndex(item => item === this.authProvider.localUser.id);
					this.flagEvent().likes.splice(myLikeIndex, 1);
				}
				this.cdRef.detectChanges();
			} else {
				this.alertProvider.publish({
					msg: `Could not ${like ? 'like' : 'unlike'} event! Try again later`,
					action: 'Oh no!', duration: 4000
				});
			}
		});
	}
}
