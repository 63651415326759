/* src/app/shared/components/user-card/user-card.component.scss */
:host {
  flex: 1 1 auto;
  display: block;
  margin: 5px;
  align-self: center;
}
.card-header .avatar {
  min-width: 2.5rem;
  min-height: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
  background-size: cover;
}
.card-header .extra .chip-item {
  display: inline-flex;
  font-size: 14px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  padding: 7px 12px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  margin: 4px;
}
.card-header a,
.user-details a {
  text-decoration: none;
  color: inherit;
}
.card-flags ::ng-deep .item-country-flag .flag-container img {
  width: 2.5rem;
}
.card-flags ::ng-deep .item-country-flag .flag-container .flag-utf {
  font-size: 2.5rem;
  margin-top: -0.3rem;
}
.card-content {
  display: flex;
}
.card-content .user-details {
  width: 40px;
  display: flex;
  gap: 0.5rem;
}
.card-content .card-main {
  flex: 1;
}
.user-flag {
  text-align: right;
}
.group-admin {
  text-align: center;
}
/*# sourceMappingURL=user-card.component.css.map */
