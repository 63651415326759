import { Component, HostBinding, input, output } from '@angular/core';
import { inOutItems } from '../../app.animations';
import User from '../../models/user.model';
import Group from '../../models/group.model';
import { UserCardComponent } from '../user-card/user-card.component';

@Component({
	animations: [ inOutItems ],
	selector: 'dflgr-user-card-list',
	standalone: true,
	imports: [UserCardComponent],
	templateUrl: './user-card-list.component.html',
	styleUrls: ['./user-card-list.component.scss']
})
export class UserCardListComponent {

	@HostBinding('class.collection') readonly classCollection = true;
	readonly users = input.required<User[]>();
	readonly group = input<Group>();
	readonly showFriendActions = input(false);
	readonly requestDeleteFromGroup = output<User>();
	readonly requestMakeGroupAdmin = output<User>();
	readonly userStatusChanged = output<User>();

}
