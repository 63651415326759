import { Component, HostBinding, ChangeDetectionStrategy, input, computed } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type Country from '../../models/country.model';
import { GENERAL } from '../../../app.constants';
import { RouterLink } from '@angular/router';
import User from '../../models/user.model';
import { FlagsService } from '../../services/flags.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CountryFlagComponent } from '../../components/country-flag/country-flag.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { HeaderHandlerComponent } from '../../components/header-handler/header-handler.component';

interface IRankedUser {
	user: User;
	rank: number;
	countries: Country[];
}

@Component({
	selector: 'dflgr-ranking-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, MatExpansionModule, NgTemplateOutlet, RouterLink, CountryFlagComponent, MatProgressSpinnerModule, AsyncPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './ranking-handler.component.html',
	styleUrls: ['./ranking-handler.component.scss']
})
export class RankingHandlerComponent {

	@HostBinding('class.handler') readonly handlerClass = true;
	@HostBinding('class.ranking') readonly handlerRankingClass = true;

	readonly handlerTitle = computed(() =>
		this.groupId() ? GENERAL.handlers.groups.ranking.name : GENERAL.handlers.friends.ranking.name
	);
	readonly groupId = input<string>('', {alias: 'groupid'}); // from route path

	constructor(
		private readonly flagsProvider: FlagsService,
	) {}

	readonly rankingSourceList$ = computed<Observable<IRankedUser[]>>(() =>
		this.flagsProvider.getRanking(this.groupId()).pipe(
			// Sort result based on ranking
			map(res => res.sort((itemA, itemB) => itemA.rank - itemB.rank))
		)
	);

	getMedal(pos: number): string {
		switch (pos) {
			case 1: return String.fromCodePoint(0x1F947); // gold medal 🥇
			case 2: return String.fromCodePoint(0x1F948); // silver medal 🥈
			case 3: return String.fromCodePoint(0x1F949); // bronze medal 🥉
			default: return '';
		}
	}

}
