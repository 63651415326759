<ng-template #userCard>
	@if (singleUser() && !country()) {
		<dflgr-user-card-big class="user-card" [user]="singleUser()"></dflgr-user-card-big>
	}
</ng-template>

@if (!firstLoad && flagEventList().length) {
	<div class="flaglist-container filtered-list-items-container has-action-button"
		id="infinite-scroller"
		dflgrInfiniteScroller
		[scrollPercent]="85"
		[isEnabled]="paginator.remain"
		[immediateCallback]="!flagEventList().length"
		[scrollCallback]="infiniteBinding"
		>
		<div class="user-card-wrapper">
			<ng-template [ngTemplateOutlet]="userCard"/>
		</div>
		@for (thisFlagEvent of flagEventList(); track thisFlagEvent.id) {
			<dflgr-flag-event
				class="item"
				[@inOutItems]
				[flagEvent]="thisFlagEvent"
				(flagEventDeleted)="removeFlagEvent(thisFlagEvent)"/>
		}
		@if (loadingMore) {
			<div class="loading-more">Loading more&hellip;</div>
		}
	</div>
} @else if (firstLoad) {
	<div class="spinner-container"><mat-spinner/></div>
} @else {
	<ng-template [ngTemplateOutlet]="userCard"/>
	<div class="empty-container">
		@if (singleUser() && country()) {
			<span>User has no flags of this country!</span>
		} @else if (singleUser() && !country()) {
			<span>User has no flags yet!</span>
		} @else if (groupId() && country()) {
			<span>This group does not have any flags of this country!</span>
		} @else if (groupId() && !country()) {
			<span>This group has no flags yet!</span>
		} @else if (country()) {
			<span>No flags of this country here yet!</span>
		} @else {
			<span>No flags here yet!</span>
		}
	</div>
}
