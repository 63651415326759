export type IMethod =  'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
interface IEndpoint { endp: string; meth: IMethod; pub: boolean; }
interface IEndpointGroup { [name: string]: IEndpoint; }

export const AUTH: IEndpointGroup = {
	LOGIN: { endp: 'auth', meth: 'POST', pub: true }, // login
	EDITPASS: { endp: 'auth', meth: 'PATCH', pub: false }, // editmypass
	LOGOUT: { endp: 'auth', meth: 'DELETE', pub: false } // logout
};
export const FLAGS: IEndpointGroup = {
	LOADUSERFLAGEVENTS: { endp: 'events', meth: 'GET', pub: false }, // userevents
	LOADUSERFLAGS: { endp: 'users/{userId}/flags', meth: 'GET', pub: false }, // userflags
	LOADGROUPFLAGS: { endp: 'groups/{groupId}/flags', meth: 'GET', pub: false }, // groupflags
	LOADUSERSBYFLAG: { endp: 'users/country/{country}', meth: 'GET', pub: false }, // country
	DELETEFLAGEVENT: { endp: 'events/{eventid}', meth: 'DELETE', pub: false }, // delflag
	GETSINGLEEVENT: { endp: 'events/{fullcode}', meth: 'GET', pub: false }, // event
	EDITEVENT: { endp: 'events/{eventid}', meth: 'PATCH', pub: false }, // editevent
	ADDFLAGEVENT: { endp: 'events', meth: 'POST', pub: false }, // addflag
	EVENTLIKE: { endp: 'events/{eventid}/like', meth: 'POST', pub: false }, // eventlike
	EVENTUNLIKE: { endp: 'events/{eventid}/like', meth: 'DELETE', pub: false }, // eventunlike
	GETCOUNTRIESLIST: { endp: 'countries', meth: 'GET', pub: true }, // countrieslist
	LOADCOUNTRYINFO: { endp: 'countries/{country}', meth: 'GET', pub: false }, // countryinfo
	USERSRANKING: { endp: 'users/ranking', meth: 'GET', pub: false }, // friendsranking
	GROUPSRANKING: { endp: 'groups/{groupId}/ranking', meth: 'GET', pub: false }, // groupranking
	REPORTEVENT: { endp: 'events/{eventid}/report', meth: 'POST', pub: false }, // reportevent
};
export const USERS: IEndpointGroup = {
	VERIFY: { endp: 'verify', meth: 'POST', pub: true }, // verify
	RECOVERYGENERATE: { endp: 'users/recover', meth: 'POST', pub: true }, // recgen
	RECOVERYSAVE: { endp: 'users/recover', meth: 'PUT', pub: true }, // recsave
	REGISTER: { endp: 'users', meth: 'POST', pub: true }, // register
	CHECKUSER: { endp: 'users/check', meth: 'GET', pub: true }, // checkuser
	USERINFO: { endp: 'users/{userId}', meth: 'GET', pub: false }, // userinfo
	GETMYINFO: { endp: 'users/me', meth: 'GET', pub: false }, // getmydetails
	DELETEACCOUNT: { endp: 'users/me', meth: 'DELETE', pub: false }, // deleteaccount
	EDITUSEREMAIL: { endp: 'users/me/email', meth: 'PATCH', pub: false }, // editemail
	EDITUSERINFO: { endp: 'users/me', meth: 'PATCH', pub: false }, // editmydetails
	SETAVATAR: { endp: 'users/avatar', meth: 'PUT', pub: false }, // setavatar
	SEARCHUSER: { endp: 'users/search', meth: 'GET', pub: false }, // searchuser
	UPDATEFRIEND: { endp: 'users/{userId}/friend', meth: 'PATCH', pub: false }, // updatefriend
	GETALLFRIENDS: { endp: 'users', meth: 'GET', pub: false }, // friends
	GETFRIENDREQUESTS: { endp: 'users/newfriends', meth: 'GET', pub: false } // requests
};
export const GROUPS: IEndpointGroup = {
	GETMINE: { endp: 'groups', meth: 'GET', pub: false }, // getmygroups
	CREATE: { endp: 'groups', meth: 'POST', pub: false }, // creategroup
	EDITNAME: { endp: 'groups/{groupId}', meth: 'PUT', pub: false }, // editgroup
	SETGROUPADMIN: { endp: 'groups/{groupId}', meth: 'PATCH', pub: false }, // setgroupadmin
	ADDFRIEND: { endp: 'groups/{groupId}/members', meth: 'PATCH', pub: false }, // addtogroup
	REMOVE: { endp: 'groups/{groupId}', meth: 'DELETE', pub: false }, // removegroup
	GETMEMBERS: { endp: 'groups/{groupId}/members', meth: 'GET', pub: false }, // getmembers
	GETDETAILS: { endp: 'groups/{groupId}', meth: 'GET', pub: false }, // groupdetails
	GETADDABLE: { endp: 'groups/{groupId}/add', meth: 'GET', pub: false } // getaddable
};
export const SETTINGS: IEndpointGroup = {
	GETALL: { endp: 'settings', meth: 'GET', pub: false }, // getall
	GETNOTIFICATIONS: { endp: 'settings/notifications', meth: 'GET', pub: false }, // getnotif
	UPDATESETTING: { endp: 'settings/{settingname}', meth: 'PATCH', pub: false }, // updset
	UPDATEALL: { endp: 'settings', meth: 'PUT', pub: false } // updall
};

export default { ...AUTH, ...FLAGS, ...USERS, ...GROUPS, ...SETTINGS };
